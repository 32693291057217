import { createContext, useContext, useEffect, useState } from 'react'
import { initializeApp } from 'firebase/app';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getFirestore } from 'firebase/firestore';

const AuthContext = createContext()

export function useAuth() {
  return useContext(AuthContext)
}


export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
}


const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
auth.useDeviceLanguage();
const appFirestore = getFirestore(app);

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const [authLoading, setAuthLoading] = useState(true);
  const [uid, setUid] = useState();

  
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user)
      setUid(user?.uid);
      setAuthLoading(false)
    });

    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    uid,
    auth,
    appFirestore
  };

  return (
    <AuthContext.Provider value={value}>
      { !authLoading && children }
    </AuthContext.Provider>
  );

}