
import { QRCodeSVG } from 'qrcode.react';
import { baseUrl } from '../../App';
import {
    useParams
} from "react-router-dom";

import { FormattedMessage } from "react-intl";

export default function QRCode() {
    let { businessId="" } = useParams();

    return (
        <div className="p-3" style={{textAlign:"center"}}>
                <h3> Qbid </h3>
                <div> <FormattedMessage id='4.Join the queue for free' /> </div>
                <div> <FormattedMessage id='4.bid to get ahead' /> </div>
                <QRCodeSVG
                    value={baseUrl+'/q/'+businessId}
                    size={128}
                    bgColor={"#ffffff"}
                    fgColor={"var(--my-purple"}
                    level={"Q"}
                    includeMargin={false}
                    imageSettings={{
                        src: "/favicon.png",
                        x: undefined,
                        y: undefined,
                        height: 32,
                        width: 32,
                        excavate: true,
                    }}
                    />
                <br />
                <p>{baseUrl+'/q/'+businessId}</p>
                
        </div>
    );
}

