
// Business ID's from list bcdghjmnpqrtvwyz, reserve 1 to 2**22 -1 for NA, 2**22 + 2**23 -1 for EU
// check first if a business is in List2

const charOrder = "bcdghjmnpqrtvwyz";
const prime = 16777213;
const maxInDb = 1000;

const dbListDev = {"na":[
    "https://deep-theorem-311306-default-rtdb.firebaseio.com/",
],
"eu":[

],
"sg":[

]
}

const dbListProd = {"na":[
    "https://qbid-na01.firebaseio.com/",
],
"eu":[

],
"sg":[

]
}

function inverse(a,n) {
    let [t, newt, r, newr] = [0, 1, n, a];
    while (newr !== 0){
        let q = Math.floor(r/newr);
        [t, newt] = [newt, t - q*newt];
        [r, newr] = [newr, r - q*newr];
    }
    return t < 0 ? t + n : t;
}

export function toNum(businessID) {
    let num = 0;
    for (const [i, c] of businessID.split("").reverse().entries()) {
        // bad  javascript modulo behavior returns a negative, watch out
        num += (charOrder.indexOf(c) - i*3 + 16) % 16 * (1 << 4*i)
    }
    return inverse(num, prime);
}

export function toId(num){
    let inv = inverse(num,prime);
    let arr = [];
    for (let i = 0; i < 6; i++) {
        arr.push(charOrder.charAt( (inv + i*3) % 16 ));
        inv = Math.floor( inv/16 );
    }
    return arr.reverse().join("");
}

export function getRealtime ( businessId ) {
    let dblocale;
    const regex = new RegExp('^[bcdghjmnpqrtvwyz]*$');

    if ( businessId.length !== 6 || !regex.test(businessId)){
        return null;
    }

    const idVal = toNum(businessId);

    

    if ( idVal < (1 << 22) )  {
        dblocale = "na";
    } else if ( idVal < ( 1 << 23) ) {
        dblocale = "eu";
    }
    else {
        dblocale = "sg";
    }

    let dbList;

    if ( process.env.REACT_APP_ENV === 'dev' ){
        dbList = dbListDev;
    } else {
        dbList = dbListProd;
    }

    const databaseURL =  dbList[dblocale][Math.floor(idVal % (1 << 22) / maxInDb)];

    return databaseURL;

} 