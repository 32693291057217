import { useState } from "react";

import { useAuth } from '../AuthContent';
import { EmailAuthProvider,
    reauthenticateWithCredential,
    updatePassword   } from "firebase/auth";

import Button from 'react-bootstrap/Button';
import { FormattedMessage } from "react-intl";

import {
        useNavigate,
        useOutletContext,
      } from "react-router-dom";


export default function ChangePass() {
    const { currentUser } = useAuth();
    const [passMatch, setPassMatch] = useState(true);
    let navigate = useNavigate();
    const addToast = useOutletContext();

    const [input, setInput] = useState({
        oldPassword: '',
        newPassword: '',
        confirmPassword: ''
      });

    const onInputChange = e => {
    const { name, value } = e.target;
    setInput(prev => ({
        ...prev,
        [name]: value
    }));
    }

    //console.log(window.location.origin+from);
  
    function handleSubmit(event) {
      event.preventDefault();

      if (input.newPassword === input.confirmPassword) {
            const credential = EmailAuthProvider.credential(
                currentUser.email,
                input.oldPassword
            )
            reauthenticateWithCredential(currentUser, credential).then(() => {
                updatePassword(currentUser, input.newPassword).then(() => {
                    navigate('/settings');
                }).catch((error) => {
                    addToast({ type:"Error", message:error.code });
                });
            }).catch((error) => {
                addToast({ type:"Error", message:error.code });

            });
        }
        else{
            setPassMatch(false)
        }

    }
  

    return (
    <div className="p-3">
        <h3><FormattedMessage id='4.Change password' /></h3>
  
        <form onSubmit={handleSubmit}>
            <p>
            <label>
            <FormattedMessage id='4.Old Password:' /> <input name="oldPassword" type="password" required value={input.oldPassword}
                        onChange={onInputChange} autoFocus/>
            </label>
            </p>
            <p>
            <label>
            <FormattedMessage id='4.New Password:' /> <input name="newPassword" type="password" required value={input.newPassword}
                        onChange={onInputChange} onFocus={() => setPassMatch(true)} />
            </label>
            </p>
            <p>
            <label>
            <FormattedMessage id='4.Confirm password:' /> <input name="confirmPassword" type="password" required value={input.confirmPassword}
                        onChange={onInputChange} onFocus={() => setPassMatch(true)} />
            </label>
            </p>
            <Button className="rounded" variant="primary" type="submit">
            <FormattedMessage id='4.Submit' />
            </Button>
            {passMatch? null : <p style={{color:"red"}}><FormattedMessage id='4.New password does not match' /></p>}
        </form>
    </div>
    );
    
  }