import { useState } from "react";
import {
    useNavigate,
    useLocation,
    useOutletContext,
  } from "react-router-dom";

import {  useAuth } from '../AuthContent';
import { createUserWithEmailAndPassword, sendEmailVerification } from "firebase/auth";
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container'
import { FormattedMessage } from "react-intl";


export default function Create() {
    let navigate = useNavigate();
    let location = useLocation();
    const {auth} = useAuth();
    const { addToast } = useOutletContext();

    const [passMatch, setPassMatch] = useState(true)

    const [input, setInput] = useState({
        email: '',
        password: '',
        confirmPassword: ''
      });

    const onInputChange = e => {
    const { name, value } = e.target;
    setInput(prev => ({
        ...prev,
        [name]: value
    }));
    }
    
    
    let from = location.state?.from?.pathname || "/";
  
    function handleSubmit(event) {
      event.preventDefault();

      if (input.password === input.confirmPassword) {
            createUserWithEmailAndPassword(auth, input.email, input.password)
        .then((userCredential) => {
            const user = userCredential.user;
            sendEmailVerification(user);
            navigate(from, { replace: true });
        }).catch((error) => {
            addToast({ type:"Error", message:error.code });
        });
      }
      else {
        setPassMatch(false)
      }

    }

  
  
    return (
      <Container>
        <h3 className="py-3"><FormattedMessage id='5.Create an account' /></h3>
  
        <form onSubmit={handleSubmit}>
          <p>
            <label>
            <FormattedMessage id='5.Email:' /> <input name="email" type="text" required value={input.email}
                        onChange={onInputChange} autoFocus/>
            </label>
          </p>
          <p>
            <label>
            <FormattedMessage id='5.Password:' /> <input name="password" type="password" required value={input.password}
                        onChange={onInputChange} onFocus={() => setPassMatch(true)} />
            </label>
          </p>
          <p>
            <label>
            <FormattedMessage id='5.Confirm password:' /> <input name="confirmPassword" type="password" required value={input.confirmPassword}
                        onChange={onInputChange} onFocus={() => setPassMatch(true)} />
            </label>
          </p>
          <Button className="rounded" variant="primary" type="submit">
          <FormattedMessage id='5.Submit' />
          </Button>
        </form>
        {passMatch? null : <p style={{color:"red"}}><FormattedMessage id='5.Password does not match' /></p>}
      </Container>
    );
  }