import { useState, useEffect, useRef } from 'react';

import {
    useNavigate,
    useOutletContext,
    useParams,
  } from "react-router-dom";

import { Modals, addModal } from '../Modals';
import { maxWidth } from '../../App';
import { useAuth } from '../AuthContent';
import { getRealtime } from '../GetRealtime';


import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';

import { getStorage, ref as storageRef, getDownloadURL  } from "firebase/storage";
import { ref, onValue, getDatabase, get, update } from "firebase/database";
import { getApp  } from "firebase/app";
import { FormattedMessage } from "react-intl";
import { useBeforeunload } from 'react-beforeunload';

// Reload the current resources from the server
// window.location.reload(true);


export default function MainPage () {
    
    const { firestore, addToast, activeBusinessId, loading, modals, setModals, setSnapInactive, setExpectQbd } = useOutletContext();
    const { uid, currentUser, appFirestore } = useAuth();
    let { businessId="" } = useParams();

    const navigate = useNavigate();
    const dcRealtime = useRef();
    const [ realtime, setRealtime] = useState(null);

    /*if (businessId === "" && firestore?.j?.b) {
        navigate(`/q/${firestore.j.b}`);
    } */

    // userState can be i = in queue, o = out of queue, or a = admin
    const [ userState, setUserState ] = useState(null);
    const [ bidInput,  setBidInput ] = useState(businessId);
    const [ localLoading, setLocalLoading ] = useState(true);
    const [ connected, setConnected ] = useState(false);
    const [ serverOffset, setServerOffset ] = useState(0);
    const [ frontUser, setFrontUser ] = useState(undefined);
    const [ sinceLast, setSinceLast ] = useState(null);
    const [ justLeft, setJustleft ] = useState(false);
    const [ numParty, setNumParty ] = useState('1');
    const [ currentBid, setCurrentBid ] = useState(0);
    const [ isAdmin, setIsAdmin ] = useState(null);

    const [ partyLow, setPartyLow ] = useState('0');
    const [ partyHigh, setPartyHigh ] = useState('9');

    const [ now, setNow ] = useState(Date.now());

    const [ imgUrl, setImgUrl ] = useState();

    const userEmail = currentUser.email;

    // display some dialogue when trying to close page
    useBeforeunload((event) => {
        if (userState === 'i') {
          event.preventDefault();
        }
    });
    
    // update time every second
    useEffect(() => {
        const interval = setInterval(() => {
            setNow(Date.now() + serverOffset);
        }, 1000);
        return () => clearInterval(interval);
    }, [ serverOffset ]);

    // set queue state based on returned queue data?
    useEffect(() => {
    if ( userState === 'o' ) {
        if ( !justLeft ) {
            addModal(setModals, 'join');
        }
    }
    }, [ setModals, justLeft, userState ]);

    // set last user who's in front:
    useEffect(() => {
        if (uid){
            setModals((old) => {
                if( frontUser ){
                    if (userState === 'a'){
                        return 'admin';
                    } else if (frontUser === uid) {
                        setSnapInactive('r');
                        return 'user';
                    } else {
                        return old;
                    }
                } else {
                    if ( old === 'admin' || old === 'user'){
                        return '';
                    } else {
                        return old;
                    }
                }
            });
        }
    }, [ userState, setModals, uid, frontUser, setSnapInactive ]);

    // main effect to subscribe to realtime database also set the connection states
    useEffect(() => {
        if (businessId !== "" && activeBusinessId === businessId && uid) {
            setLocalLoading(true);
            const databaseURL = getRealtime(businessId);
            const realtimeRef = getDatabase(getApp(databaseURL));
            get(ref(realtimeRef, `b/${businessId}/o/expiration`)).then((snapshot) => {
                if (snapshot.val()) {
                    setIsAdmin(snapshot.val());
                }
            }).catch((_error) => {
                //console.log('Not an admin');
            });
            
            const unsub = onValue(ref(realtimeRef, `b/${businessId}/p`), (snapshot) => {
                const data = snapshot.val();
                setRealtime( data ); 

                const isInqueue = data?.q?.r?.[uid] || data?.q?.f?.u === uid ? true : false;
                setUserState( (old) => {
                    if (old !== 'a'){
                        return isInqueue ? 'i' : 'o';
                    } else {
                        // as admin remove all dangling disconnect refs for people who are no longer in queue
                        if (data?.q?.r) {
                            const toRemove = Object.keys(data?.q?.r).filter(key => (!data?.q?.r?.[key]?.t) );
                            if (toRemove.length > 0){
                                update(ref(realtimeRef, `b/${businessId}/p/q/r`), toRemove.reduce((a, v) => ({ ...a, [v]: null}), {}) );
                            }
                        }
                        return 'a';
                    }
                } );

                if( data?.q?.r?.[uid]?.l !== undefined) {
                    update( ref(realtimeRef, `b/${businessId}/p/q/r/${uid}`), { l:null } );
                }

                setFrontUser((old)=>{
                    if (data?.q?.f?.u !== old){
                        setSinceLast(Date.now());
                    }
                    return data?.q?.f?.u; });
                setNumParty( data?.q?.r?.[uid]?.s || '1');
                setCurrentBid( data?.q?.r?.[uid]?.b || 0);

                const storage = getStorage();
                const imgRef = storageRef(storage, 'img/'+data?.i?.owner+'/'+businessId+'_64x64');
                getDownloadURL(imgRef).then((url) => {
                    setImgUrl(url);
                }).catch((_errors) => {});

                setLocalLoading(false);
            });
            const connectedRef = ref(realtimeRef, ".info/connected");
            onValue(connectedRef, (snap) => {
            if (snap.val() === true) {
                setConnected(true);
            } else {
                setConnected(false);
            }
            });
            dcRealtime.current = unsub;
            setLocalLoading(false);

        }
    },[ activeBusinessId, businessId, uid ]);


    // disconnect realtime when unmount
    useEffect(() => {
        return () => {
          dcRealtime.current && dcRealtime.current();
        };
    }, []);

    // set some connected timestamp stats
    useEffect(() => {
        if (businessId !== "" && activeBusinessId === businessId && uid) {
            if ( connected ){
                const databaseURL = getRealtime(businessId);
                const realtimeRef = getDatabase(getApp(databaseURL));
                const offsetRef = ref(realtimeRef, ".info/serverTimeOffset");
                onValue(offsetRef, (snap) => {
                    const offset = snap.val();
                    setServerOffset(offset);
                });
            }
        }
    }, [ connected, activeBusinessId, businessId, uid  ]);

    // main button on the page
    function handleSubmit(event) {
        event.preventDefault();
       
        if (userState === 'i'){ 
            addModal(setModals, 'leave');
        } else if ( userState === 'a' ) {
            addModal(setModals, 'leave');
        } else if (userState === 'o' ) {
            if (bidInput !== businessId) {
                navigate(`/q/${bidInput}`);
            } else if ( bidInput !== ""){
                addModal(setModals, 'join');
            }
        } else {
            if (bidInput !== businessId) {
                navigate(`/q/${bidInput}`);
            } else if ( bidInput !== ""){
                addModal(setModals, 'join');
            }
        }
    }

    const tsMinuteFormat = (dt) => {
        if (dt < 0) {
            return '00:00';
        }
        const minutes = Math.floor(dt/60000);
        const seconds = Math.floor((dt / 1000)%60);
        return (minutes < 10 ? "0":"") + minutes + ":" + (seconds < 10 ? "0":"") + seconds;
    }


    return(
        <>
            <div /*className="px-3"*/ style={ {marginBottom:"6rem"} }> {/* marginBottom is for the footer not covering the bottom of the page scroll */}
                

                <Container className="mx-auto">
                    <form onSubmit={handleSubmit}>
                        <Row className = "py-2 bg-primary text-light">
                            
                            <Col>
                                <label> BusinessID:&nbsp; </label>
                                <input name="bidInput" type="text" size="12" value={bidInput}
                                    required onChange={(e) => setBidInput(e.target.value)} autoFocus/>
                            </Col>

                            <Col className='align-self-end'>
                                {userState === 'o' || userState === null ?
                                <Button className="bg-light rounded" variant="outline-success rounded" disabled={loading} type="submit" >
                                    <FormattedMessage id='3.Join' />
                                </Button> 
                                :
                                <Button className="bg-light rounded" variant="outline-danger rounded" disabled={loading} type="submit" >
                                    <FormattedMessage id='3.Leave' />
                                </Button> 
                                }
                            </Col>
           
                            <Col className='align-self-end'>
                                { userState === 'a' &&
                                    <div> <FormattedMessage id='3.Admin Mode' /> {/* isAdmin - now < 0 ? 'expired' :(isAdmin - now < 86400000*2 ? '1 day' : Math.floor((isAdmin - now)/86400000) + ' days')*/}</div>
                                }
                            </Col>

                        </Row>
                    </form>



                    <Row className = "py-2"> 
                        <Col xs={3} className="my-auto">
                            {!imgUrl && <img alt="business profile" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAQAAAAAYLlVAAAAOUlEQVR42u3OIQEAAAACIP1/2hkWWEBzVgEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAYF3YDicAEE8VTiYAAAAAElFTkSuQmCC"
                            width={64} heigh={64} ></img>}
                            {imgUrl && <img alt="business profile" src={imgUrl} width={64} heigh={64} ></img>}
                        </Col>
                        <Col xs={7} style={{overflowX:"auto"}}>
                            <Row style={{whiteSpace: 'nowrap'}}> <b>{realtime?.i?.name}</b> </Row>
                            <Row style={{whiteSpace: 'nowrap'}}> <span>{realtime?.i?.add1}</span> </Row>
                            <Row style={{whiteSpace: 'nowrap'}}> <span>{realtime?.i?.add2}</span> </Row>
                        </Col>
                        <Col xs={2} className="my-auto">
                            <Row> <div>
                                <Button variant="outline-primary" disabled={loading} href={`tel:${realtime?.i?.phone}`} >
                                ☎️ </Button> 
                            </div> </Row>
                            <Row> <div>
                                <Button variant="outline-primary" disabled={loading} target="_blank" href={`http://maps.google.com/?q=${ encodeURIComponent((realtime?.i?.add1 ?? '') + " " + (realtime?.i?.add2 ?? '')) }`} >
                            🗺️ </Button> 
                            </div> </Row>
                        </Col>
                              
                    </Row>

                    <Row className = "py-2"> 
                        <Col xs={6} className="my-auto">
                            <strong>Rate:</strong> 1 min = 🟡 { realtime?.i?.dpm.toFixed(2) } 
                        </Col>
                        <Col xs={6} >
                            <strong>Max bid:</strong> { realtime?.i?.max ?? <FormattedMessage id='3.unlimited' />} { realtime?.i?.max && 'min' }
                        </Col>
                              
                    </Row>
                    
                    <Row className = "py-2 bg-primary text-light sticky-top" style={{top:"82px"}}> 
                            <Col xs={2}> # </Col>  
                            <Col xs={6}> <FormattedMessage id='3.Wait time + bid (min)' /> </Col>
                            <Col xs={2} className='text-center'> <span className="bg-light">👤</span> x? </Col>
                            <Col xs={2} className='text-end'> <FormattedMessage id='3.Status' /> </Col>
                    </Row>
                    

                    {userState !=='o' && realtime?.q?.r && Object.keys(realtime.q.r).sort((a,b) => {
                            if (!realtime.q.r[a].t){
                                return -1;
                            } else if (!realtime.q.r[b].t) {
                                return 1;
                            } else {
                                return (realtime.q.r[a].t/60000 - realtime.q.r[a].b) - (realtime.q.r[b].t/60000 - realtime.q.r[b].b);
                            }
                         }).map( ( key, index ) => 
                            { return realtime.q.r[key].t && <Row key={key} className = {`py-2 ${ key === uid && "bg-light"}`} >
                                <Col xs={2}> {index+1}. </Col>  
                                <Col xs={6} style={{whiteSpace: 'nowrap', overflowX:"hidden"}}>
                                    { (userState !=='a' || !key.startsWith('@')) &&
                                    `${tsMinuteFormat(now - realtime.q.r[key].t + realtime.q.r[key].b*60000)} = ${tsMinuteFormat(now - realtime.q.r[key].t )} + ${realtime.q.r[key].b}`
                                    }
                                    { userState ==='a' && key.startsWith('@') &&
                                    `${tsMinuteFormat(now - realtime.q.r[key].t + realtime.q.r[key].b*60000)} ${key.substring(1)}`
                                    }
                                </Col>
                                <Col xs={2} className='text-center'> {realtime.q.r[key].s} </Col>
                                <Col xs={2} className='text-end'> {key.startsWith('@') ? '🟣' : ( key === uid ? ( connected? '🟢':'🔴') : (realtime.q.r[key].l ? '🔴':'🟢') ) } </Col>
                            </Row>}
                    )}

                    
                    {(userState === 'o' || userState === null) &&
                    <>
                        <br />
                        <br />
                        <Row className = 'py-2 text-center' >
                            <p> <FormattedMessage id='3.Currently not in queue, click ' /> 
                            <strong><FormattedMessage id='3.Join' /></strong> <FormattedMessage id='3.above.' /></p>
                        </Row>
                    </>
                    }




                </Container>

            </div>




        {/* Footers */}

        {(userState === 'a') && (
                <Container  className="mx-auto bg-light" style={{maxWidth:maxWidth,position:"fixed",bottom:"0",marginLeft:"0"}}>
                    <Row className='py-2'>
                        <Col xs={3} className='align-self-center'> 
                            <Button className="bg-light rounded" variant="outline-primary" disabled={loading} onClick={ () => {
                                setPartyLow('1');
                                setPartyHigh('2');
                                addModal(setModals,'get');} }>
                                👤1-2
                            </Button> 
                        </Col>
                        <Col xs={3} className='align-self-center'> 
                            <Button className="bg-light rounded" variant="outline-primary" disabled={loading} onClick={ () => {
                                setPartyLow('3');
                                setPartyHigh('4');
                                addModal(setModals,'get');} }>
                                👤3-4
                            </Button> 
                        </Col>
                        <Col xs={3} className='text-end'>
                            <Button className="bg-light rounded" variant="outline-primary" disabled={loading} onClick={ () => {
                                setPartyLow('1');
                                setPartyHigh('9');
                                addModal(setModals,'get');} }>
                                👤1-9
                            </Button> 
                        </Col>
                        <Col xs={3} className='text-end'> 
                            <Button className="bg-light rounded" variant="outline-primary" disabled={loading} onClick={ () => {
                                addModal(setModals,'get');} }>
                                👤x?
                            </Button> 
                        </Col>
                    </Row>
                    <Row className='py-2'>
                        <Col xs={3} className='align-self-center'> Σ: { Object.keys(realtime?.q?.r ?? {}).length }  </Col>
                        <Col xs={3} className='align-self-center'> 🔄: {sinceLast !==null && tsMinuteFormat(now - sinceLast - serverOffset)} </Col>
                        <Col xs={3} className='text-end'> 
                            <Button className="bg-light rounded" variant="outline-danger" disabled={loading} onClick={ () => { addModal(setModals, 'purge'); } }>
                            <FormattedMessage id='3.Purge' />
                            </Button>
                        </Col>
                        <Col xs={3} className='text-end'> 
                            <Button className="bg-light rounded" variant="outline-success" disabled={loading} onClick={ () => { addModal(setModals,'add'); } }>
                            <FormattedMessage id='3.Add' />
                            </Button> 
                        </Col>
                    </Row>
                </Container>
        )}

        {(userState !== 'a') && (
            <Container  className="mx-auto bg-light" style={{maxWidth:maxWidth,position:"fixed",bottom:"0",marginLeft:"0"}}>
                
                {realtime?.q?.r && Object.keys(realtime.q.r).sort((a,b) => {
                            if (!realtime.q.r[a].t){
                                return -1;
                            } else if (!realtime.q.r[b].t) {
                                return 1;
                            } else {
                                return (realtime.q.r[a].t/60000 - realtime.q.r[a].b) - (realtime.q.r[b].t/60000 - realtime.q.r[b].b);
                            }
                         }).filter((key) => {
                            return (key === uid);
                         }).map( ( key, index ) => 
                            { return realtime.q.r[key].t && <Row key={key} className = {`py-2`} >
                                <Col xs={2}> {index+1}. </Col>  
                                <Col xs={6}> 
                                    {tsMinuteFormat(now - realtime.q.r[key].t + realtime.q.r[key].b*60000)} = {tsMinuteFormat(now - realtime.q.r[key].t )} + {realtime.q.r[key].b}
                                </Col>
                                <Col xs={2} className='text-center'> {realtime.q.r[key].s} </Col>
                                <Col xs={2} className='text-end'> { connected? '🟢'  : '🔴' } </Col>
                            </Row>}
                )}
                
                <Row className='py-2'>
                    <Col xs={3} className='align-self-center'> Σ: { Object.keys(realtime?.q?.r ?? {}).length }  </Col>
                    <Col xs={3} className='align-self-center'> 🔄: {sinceLast !==null && tsMinuteFormat(now - sinceLast - serverOffset)} </Col>
                    <Col xs={3} className='text-end'> 
                        <Button className="bg-light rounded" variant="outline-primary" disabled={loading || userState !== 'i'} onClick={ () => { addModal(setModals, 'change') } }>
                            👤 x?
                        </Button>
                    </Col>
                    <Col xs={3} className='text-end'> 
                        <Button className="bg-light rounded" variant="outline-primary" disabled={loading || userState !== 'i' } onClick={ () => { addModal(setModals, 'bid') } }>
                        <FormattedMessage id='3.Bid' />
                        </Button> 
                    </Col>
                </Row>

            </Container>
        )}

            <Modals { ...{ modals,  setModals, addToast, businessId, activeBusinessId, uid, userState, setJustleft, numParty, appFirestore, sinceLast, serverOffset,
                isAdmin, setUserState, realtime, tsMinuteFormat, now, currentBid, firestore, localLoading, setLocalLoading, partyLow, setPartyLow, partyHigh, setPartyHigh, 
                userEmail, setExpectQbd } } />
        </>
    )
}

