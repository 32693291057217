
import {
    useNavigate,
  } from "react-router-dom";

import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';


import { FormattedMessage } from "react-intl";

// Reload the current resources from the server
// window.location.reload(true);


export default function HomePage () {
    

    const navigate = useNavigate();

    return(
        <div /*className="px-3"*/ style={ {marginBottom:"6rem"} }> {/* marginBottom is for the footer not covering the bottom of the page scroll */}
            

            <Container className="mx-auto">
                <h1 className="py-3"> <FormattedMessage  id='0.Welcome to Qbid' /> </h1>

                <p> <FormattedMessage  id='0.Get into a queue by clicking this button:' /> </p>

                <Button onClick={() => navigate('/q')} className="rounded">  <FormattedMessage  id='0.Queue' />  </Button>

                <h3 className="py-3"> <FormattedMessage  id='0.Business owners:' />  </h3>
                <p><FormattedMessage  id='0.Setup your queue from the settings menu: ⚙' /> </p>

            </Container>

        </div>

    )
}

