import { useState } from "react";

import {  useAuth } from '../AuthContent';
import {  sendPasswordResetEmail  } from "firebase/auth";
import {
    useLocation,
    useOutletContext
  } from "react-router-dom";

import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container'
import { FormattedMessage } from "react-intl";


export default function Reset() {
    const [sent, setSent] = useState(false);
    const [email, setEmail] = useState("");
    const { auth } = useAuth();
    const addToast = useOutletContext();
    
    const location = useLocation();
    const from = location.state?.from?.pathname || "/";
    const actionCodeSettings = {
        url: window.location.origin+from
    }

    //console.log(window.location.origin+from);
  
    function handleSubmit(event) {
      event.preventDefault();
        
      sendPasswordResetEmail(auth, email, actionCodeSettings)
      .then(() => {
        setSent(true);
      }).catch((error) => {
        addToast({ type:"Error", message:error.code });
      })

    }
  

    return (
    <Container>
        <h3 className="py-3"><FormattedMessage id='5.Reset Password' /></h3>

        <form onSubmit={handleSubmit}>
        <p>
            <label>
            <FormattedMessage id='5.Email:' /> <input name="email" type="text" required value={email}
                        onChange={(e) => setEmail(e.target.value) } autoFocus />
            </label>
        </p>
        <Button className="rounded" variant="primary" type="submit"  disabled={sent}>
        <FormattedMessage id='5.Submit' />
        </Button>
        </form>
        {sent && <p> <FormattedMessage id='5.Reset link sent, check your inbox or spam folder.' /></p>}
    </Container>
    );
    
  }