
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import { useState, useEffect, useRef } from 'react';

import { ref, remove, update, getDatabase, serverTimestamp, runTransaction, push, onDisconnect, set } from "firebase/database";
import { doc, updateDoc } from "firebase/firestore";
import { getApp  } from "firebase/app";
import { httpsCallable, getFunctions  } from "firebase/functions";

import { getRealtime } from './GetRealtime';
import { getCurrency } from './i18n/messages';
import { baseUrl, homeUrl, termsUrl, privacyUrl } from '../App';


import Papa from "papaparse";
import PinInput from 'react-pin-input';
import { FormattedMessage } from "react-intl";



export function addModal (setModals, type ) {
  // null dependency means remove it, only one active, admin and user replaces old one
  
  setModals( (old) => {
    if ([ 'admin', 'user' ].includes(old)){
      return old;
    } else {
      return type;
    }
  })
}

export function Modals({ modals, setModals, addToast, businessId, activeBusinessId, uid, userState, setJustleft, numParty, isAdmin, setUserState, realtime,
  tsMinuteFormat, now, currentBid, firestore, localLoading, setLocalLoading, partyLow, setPartyLow, partyHigh, setPartyHigh, appFirestore, sinceLast, serverOffset, userEmail, setExpectQbd }){
    // modal types: join, leave, change, buy, bid, get, admin, user, add, purge

  
  const createSession = httpsCallable( getFunctions(getApp()), 'createSession');
  const getQueueCustomer = httpsCallable( getFunctions(getApp(), 'northamerica-northeast1'), 'getQueueCustomer');

  const type = modals;
  

  const [input, setInput] = useState({
    num: numParty,
    bid: currentBid,
    currency: getCurrency( navigator.language || navigator.userLanguage ),
    customerName: '',
    pin : ''
  });

  const [ errorMsg, setErrorMsg ] = useState('');

  const [ prices, setPrices ] = useState([]);

  const dcRealtimeRef = useRef();

  useEffect( () => {
    setErrorMsg('');
  },[type]);

  useEffect( () => {
    fetch(`/prices.${process.env.REACT_APP_ENV}.csv`).then(
        (r) => r.text()
    ).then(
        (r) =>
          Papa.parse(r, {
            header:true,
            complete: (result) => {
                setPrices(result.data);
            }
        })
    );
  },[]);

  const [realtimeRef, setRealtimeRef ] = useState(null);

  useEffect(() => {
    if (businessId !== "" && activeBusinessId === businessId && uid) {
      const databaseURL = getRealtime(businessId);
      setRealtimeRef( getDatabase(getApp(databaseURL)) );
    }
  },[ activeBusinessId, businessId, uid ]);
  
  const hasClose = ['join','leave','change','buy','bid','get','add','purge', 'home' ].includes(type);

  function handleClose (){
      addModal(setModals, '' );
  };

  const onInputChange = e => {
    let { name, value } = e.target;

    setInput(prev => ({
        ...prev,
        [name]: value
  }))};

  function joinQueue(){
    setLocalLoading(true);
    if (uid && realtimeRef !== null){
        if ( userState === 'i' ){
            update( ref(realtimeRef, `b/${businessId}/p/q/r/${uid}`), 
            { s: parseInt(input.num).toString() }).catch(
              (error) => addToast({type:"Error", message:error.code}) ).finally(() => handleClose());
        } else if ( userState ==='o' ){
            const toUpdate = { 
                t: serverTimestamp(),
                b: 0,
                s: parseInt(input.num).toString()
            };
            update( ref(realtimeRef, `b/${businessId}/p/q/r/${uid}`), toUpdate ).then(
              () => {
                if (businessId !== firestore?.j?.b) {
                  const docRef = doc( appFirestore, "u", uid);
                  updateDoc(docRef, {
                    j: { b:businessId, p: Math.floor(Math.random()*10000) }
                  });
                }
              const userLastOnlineRef = ref(realtimeRef, `b/${businessId}/p/q/r/${uid}/l`);
              dcRealtimeRef.current = onDisconnect(userLastOnlineRef);
              dcRealtimeRef.current.set(serverTimestamp()).catch((error) => { console.log( error +'' ) });
            }).catch(
              (error) => addToast({type:"Error", message:error.code}) ).finally(() => handleClose());
        }
    } else {
      handleClose();
    }
    setLocalLoading(false);
  };

  switch (type){ 
    case '':
      return (<></>);
    case 'join':
      return(
          <Modal
          show={true}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          centered
        >
          <Modal.Header closeButton={hasClose} >
            <Modal.Title> <FormattedMessage id='2.Join the queue' /> </Modal.Title>
          </Modal.Header>
          <Modal.Body>
          
              
            <label><FormattedMessage id='2.Number of people in your group:' />  </label>
            <div>
            <span style={{display: 'inline-block'}} className = 'm-2 px-2 border border-secondary rounded' onClick={
              () => setInput({ ...input, num: parseInt(input.num) <=1 ? '1': (parseInt(input.num)-1).toString() })}> - </span>
            <input style={{display: 'inline-block', textAlign: 'center'}} name="num" type="text" size="1" required value={input.num}
                    readOnly onChange={onInputChange} autoFocus/>

            <span style={{display: 'inline-block'}} className = 'm-2 px-2 border border-secondary rounded' onClick={
              () => setInput({ ...input, num: parseInt(input.num) >= 9 ? '9+': (parseInt(input.num)+1).toString() })}> + </span>
            </div>

            <p> <FormattedMessage id='2.*By joining the queue, I agree to the' /> <a href={termsUrl} target="_blank" rel="noreferrer"><FormattedMessage id='2.terms of service' /></a> <FormattedMessage id='2.and' /> <a href={privacyUrl} target="_blank" rel="noreferrer"> <FormattedMessage id='2.privacy policy.' /></a> 
            <FormattedMessage id='2.In addition I understand I might be removed by the queue administrator for not present when called or for any other reason.' />
            </p>
          
          </Modal.Body>
          <Modal.Footer>
            { isAdmin && <Button className="rounded" disabled={localLoading} variant="warning" onClick = { () => {
            setUserState('a');
            handleClose();
            }}>
              <FormattedMessage id='2.Join as admin' />
            </Button> }
            <Button className="rounded" variant="primary" disabled={localLoading} onClick = {() => {
              joinQueue();
            }}>
              <FormattedMessage id='2.Join' />
            </Button>
          </Modal.Footer>
        </Modal>
      )

    case 'leave':
      return(
          <Modal
          show={true}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          centered
        >
          <form onSubmit={ (event) =>  {
          event.preventDefault();
          setJustleft(true);
          if ( userState === 'i' && realtimeRef !== null){
            const inQueue = realtime?.q?.r?.[uid];
            remove( ref(realtimeRef, `b/${businessId}/p/q/r/${uid}`) ).then(
              () => {
                push(ref(realtimeRef, `b/${businessId}/l`), {  u:uid, m:'d', t:inQueue.t, o:serverTimestamp(), s:inQueue.s, b:inQueue.b } );
                if (dcRealtimeRef.current){
                  dcRealtimeRef.current.cancel();
                }
                handleClose();
              }
            )
          } else if ( userState === 'a'){
            setUserState('o');
            handleClose();
          }
        }}>
          <Modal.Header closeButton={hasClose} >
            <Modal.Title> <FormattedMessage id='2.Leave queue' /> </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            { userState === 'i' && <p> <FormattedMessage id='2.Are you sure you would like to leave the queue? Your place will be lost.' /> </p>}
            { userState === 'a' && <p> <FormattedMessage id='2.Are you sure you would like to leave as the admin?' /> </p> }

            <label>
              <input type='checkbox' name="confirm" required/> <FormattedMessage id='2.Confirm leave.' />
            </label>
          </Modal.Body>
          <Modal.Footer>
          <Button  className="rounded" disabled={localLoading} variant="danger" type="submit" >
            <FormattedMessage id='2.Leave' />
          </Button>
          </Modal.Footer>
          </form>
        </Modal>
      )
    
    case 'change':
      return(
          <Modal
          show={true}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          centered
        >
          <Modal.Header closeButton={hasClose} >
            <Modal.Title> <FormattedMessage id='2.Change group size' /> </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <label> <FormattedMessage id='2.Number of people in your group:' /> </label>
              <div>
              <span style={{display: 'inline-block'}} className = 'm-2 px-2 border border-secondary rounded' onClick={
                () => setInput({ ...input, num: parseInt(input.num) <=1 ? '1': (parseInt(input.num)-1).toString() })}> - </span>
              <input style={{display: 'inline-block', textAlign: 'center'}} name="num" type="text" size="1" required value={input.num}
                      readOnly onChange={onInputChange} autoFocus/>

              <span style={{display: 'inline-block'}} className = 'm-2 px-2 border border-secondary rounded' onClick={
                () => setInput({ ...input, num: parseInt(input.num) >= 9 ? '9+': (parseInt(input.num)+1).toString() })}> + </span>
              </div>
          </Modal.Body>
          <Modal.Footer>
            <Button className="rounded" variant="primary" disabled={localLoading} onClick={() => {
                joinQueue();
              }}>
                <FormattedMessage id='2.Submit' />
            </Button>
          </Modal.Footer>
        </Modal>
      )
    
    case 'bid':
      return(
          <Modal
          show={true}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          centered
        >
          <Modal.Header closeButton={hasClose} >
            <Modal.Title>  <FormattedMessage id='2.Bid for your place in queue' /> </Modal.Title>
          </Modal.Header>
           
          <Modal.Body>
            <span> <strong> <FormattedMessage id='2.Rate:' /> </strong> 1 min = 🟡 { realtime?.i?.dpm.toFixed(2) }</span>
            <span className='float-end'> <strong><FormattedMessage id='2.Max bid:' /></strong> { realtime?.i?.max ?? <FormattedMessage id='2.unlimited' />} { realtime?.i?.max && 'min' } </span>
              <div>
                <label> <FormattedMessage id='2.Bid in minutes:' /> </label>
                <span style={{display: 'inline-block'}} className = 'm-2 px-2 border border-secondary rounded' onClick={
                  // x*1 is a hack to convert string to a num simply
                  () => setInput({ ...input, bid: input.bid*1 - 1 <=0 ? 0: input.bid*1 - 1 })}> - </span>
                <input style={{display: 'inline-block', textAlign: 'center', width:'4rem'}} min="0" name="bid" type="number" required value={ input.bid }
                            onChange={onInputChange} onBlur={() => {
                              setInput({ ...input, bid: input.bid*1 > realtime?.i?.max ? realtime?.i?.max : ( input.bid*1 <=0 ? 0: input.bid*1 ) });
                              }} autoFocus/>
                <span style={{display: 'inline-block'}} className = 'm-2 px-2 border border-secondary rounded' onClick={
                  () => setInput({ ...input, bid:  input.bid*1 + 1 > realtime?.i?.max ? realtime?.i?.max : input.bid*1 + 1 })}> + </span>
              </div>

                <span> <FormattedMessage id='2.For:' /> {`${input.bid * 1} x ${realtime?.i?.dpm.toFixed(2)} = 🟡 ${(input.bid * realtime?.i?.dpm).toFixed(2)} `} </span>
                <span className={ 100*input.bid*realtime?.i?.dpm > firestore?.qbd ? 'text-danger' : 'text-white'}> <FormattedMessage id='2.Insufficient fund' /> </span> 

              <h5 className='pt-3'><FormattedMessage id='2.Your place:' /></h5>
              {realtime?.q?.r && Object.keys(realtime.q.r).sort((a,b) => {
                  if (!realtime.q.r[a].t){
                    return -1;
                  } else if (!realtime.q.r[b].t) {
                    return 1;
                  } else {
                    return (realtime.q.r[a].t/60000 - realtime.q.r[a].b) - (realtime.q.r[b].t/60000 - realtime.q.r[b].b);
                  }
                  }).filter((key) => {
                  return (key === uid);
                  }).map( ( key, index ) => 
                  {return realtime.q.r[key].t && <Row key={key}>
                    <Col xs={3}><span> <FormattedMessage id='2.Before:' /> </span> </Col>
                    <Col> <span className='me-2'>{index+1}. </span> {tsMinuteFormat(now - realtime.q.r[key].t + realtime.q.r[key].b*60000)} = {tsMinuteFormat(now - realtime.q.r[key].t )} + {realtime.q.r[key].b} </Col>
                </Row>}
              )}

              {realtime?.q?.r && Object.keys(realtime.q.r).sort((a,b) => {
                  const abid = a === uid ? input.bid : realtime.q.r[a].b;
                  const bbid = b === uid ? input.bid : realtime.q.r[b].b;
                  if (!realtime.q.r[a].t){
                    return -1;
                  } else if (!realtime.q.r[b].t) {
                    return 1;
                  } else {
                    return (realtime.q.r[a].t/60000 - abid) - (realtime.q.r[b].t/60000 - bbid);
                  }
                  }).filter((key) => {
                  return (key === uid);
                  }).map( ( key, index ) => 
                  {return realtime.q.r[key].t && <Row key={key}>
                    <Col xs={3}><span> <FormattedMessage id='2.After:' /> </span></Col>
                    <Col>  <span className='me-2'>{index+1}. </span> {tsMinuteFormat(now - realtime.q.r[key].t + input.bid*60000)} = {tsMinuteFormat(now - realtime.q.r[key].t )} + {input.bid} </Col>
                </Row>}
            )}
            <Row className='py-2'><p> <FormattedMessage id='2.*Your bid will only be deducted when you are called to the front of the queue, you can modify your bid at any time.' /> </p></Row>

          </Modal.Body>
          
          <Modal.Footer>
          { errorMsg && <span className='text-danger'> {errorMsg} </span>}
          { 100*input.bid*realtime?.i?.dpm  > firestore?.qbd && 
            <Button className="rounded" variant='warning' onClick = { () => {
            addModal(setModals,'buy');
            }}> <FormattedMessage id='2.Recharge' /> </Button> 
          }

          <Button className="rounded" variant='primary' disabled={ 100*input.bid*realtime?.i?.dpm  > firestore?.qbd || localLoading} 
            onBlur={ () => setErrorMsg('') } onClick = { () => {
              if (realtimeRef !== null){
                setLocalLoading(true);
                update( ref(realtimeRef, `b/${businessId}/p/q/r/${uid}`), 
                  {b: input.bid*1  } ).then(() => setExpectQbd(firestore?.qbd - Math.floor(100*input.bid*realtime?.i?.dpm)) ).catch(
                  (error) => setErrorMsg(error+'') ).finally(() => handleClose());
                setLocalLoading(false);
              }
            }}> <FormattedMessage id='2.Bid' /> </Button>
          </Modal.Footer>

        </Modal>
      )
    
    case 'buy':
      return(
          <Modal
          show={true}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          centered
        >
          <Modal.Header closeButton={hasClose} >
            <Modal.Title> <FormattedMessage id='2.Recharge account' /> </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
            {'Currency: '}
              <select onChange={(e) => {
                setInput({...input, currency:e.target.value});
                }} value={input.currency}>
                {[ 'usd' ].map((name) => (
                  <option key={name} value={name}>
                    {name}
                  </option>
                ))}
              </select>
            </p>

            { prices.map( (obj) =>(
                 (obj['Currency'] === input.currency  && obj['Product Name'].startsWith('Recharge')) && 
                 (
                <Row className='py-2' key={obj['Price ID']} >
                  <Col className='my-auto' xs={7}> <span> {`Recharge 🟡${obj['Product Name'].split(' ')[1]}.00 `}  </span> </Col>
                  <Col xs={5}> <span className='float-right'>
                      <Button className="rounded" variant="outline-warning" disabled = {localLoading} key={obj['Price ID']} name={obj['Price ID']} onClick={
                        (e) => {
                          const data = {
                            success_url : `${baseUrl}/${businessId}?qbd=${firestore?.qbd + obj['Product Name'].split(' ')[1]*100}` , 
                            cancel_url : baseUrl + '/' + businessId , 
                            customer: firestore.stripeId, 
                            priceId : e.nativeEvent.target.name,
                            metadata: {}
                          };
                          setLocalLoading(true);
                          createSession( data ).then((result) => {
                              if (result.data.url){
                                  window.location.replace( result.data.url );
                                  setLocalLoading(false);
                              } else {
                                  addToast( {type:"Error", message:result+''} );
                                  setLocalLoading(false);
                              }
                          }).catch((error) => {
                              setLocalLoading(false);
                              addToast( {type:"Error", message:error+''} );});
                      }}>
                        {obj['Amount']/100} {obj['Currency']}
                      </Button>
                    </span> </Col>
                </Row>)
               
            )) }

          </Modal.Body>
          <Modal.Footer>
          <Button className="rounded" variant="outline-danger" disabled={localLoading} onClick={() => {
                handleClose();
              }}>
                <FormattedMessage id='2.Close' />
            </Button>
          </Modal.Footer>
        </Modal>
      )
    
    case 'get':
      return(
          <Modal
          show={true}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          centered
        >
          <Modal.Header closeButton={hasClose} >
            <Modal.Title> <FormattedMessage id='2.Get next in queue' /> </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h5> <FormattedMessage id='2.Group size' /></h5>
            <Row>
              <Col><FormattedMessage id='2.From:' /></Col>
              <Col><FormattedMessage id='2.To:' /></Col>
            </Row>
            <Row>
              <Col>
                <span style={{display: 'inline-block'}} className = 'm-2 px-2 border border-secondary rounded' onClick={
                  () => setPartyLow(parseInt(partyLow) <= 1 ? '1': (parseInt(partyLow)-1).toString() )}> - </span>
                <input style={{display: 'inline-block', textAlign: 'center'}} name="num" type="text" size="1" required value={partyLow}
                            readOnly autoFocus/>

                <span style={{display: 'inline-block'}} className = 'm-2 px-2 border border-secondary rounded' onClick={
                  () => setPartyLow(parseInt(partyLow) >= parseInt(partyHigh) ? partyHigh: (parseInt(partyLow)+1).toString() )}> + </span>
              </Col>
              <Col>
                <span style={{display: 'inline-block'}} className = 'm-2 px-2 border border-secondary rounded' onClick={
                  () => setPartyHigh(parseInt(partyHigh) <= parseInt(partyLow) ? partyLow : (parseInt(partyHigh)-1).toString() )}> - </span>
                <input style={{display: 'inline-block', textAlign: 'center'}} name="num" type="text" size="1" required value={partyHigh}
                            readOnly autoFocus/>

                <span style={{display: 'inline-block'}} className = 'm-2 px-2 border border-secondary rounded' onClick={
                  () => setPartyHigh(parseInt(partyHigh) >= 9 ? '9+': (parseInt(partyHigh)+1).toString() )}> + </span>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            { errorMsg && <span className='text-danger'> {errorMsg} </span>}
            <Button className="rounded" disabled={localLoading} variant='primary' onBlur={() => { setErrorMsg('') }} onClick={ () => {
              setLocalLoading(true);
              const filtered = realtime?.q?.r && Object.keys(realtime.q.r).sort((a,b) => {
                if (!realtime.q.r[a].t){
                  return -1;
                } else if (!realtime.q.r[b].t) {
                  return 1;
                } else {
                  return (realtime.q.r[a].t/60000 - realtime.q.r[a].b) - (realtime.q.r[b].t/60000 - realtime.q.r[b].b);
                }
                }).filter((key) => {
                return (realtime.q.r[key].s >= parseInt(partyLow).toString() &&  realtime.q.r[key].s <= parseInt(partyHigh).toString());
                });

              if ( !filtered || filtered.length < 1) {
                setErrorMsg('No one in queue match criteria');
              } else {
                if ( userState === 'a' && realtimeRef !== null){
                  setLocalLoading(true);
                  runTransaction( ref(realtimeRef, `b/${businessId}/p/q/f`), (customer) => {
                    if (!customer && realtime?.q?.r?.[filtered[0]]){
                      const toUpdate = { ...realtime.q.r[filtered[0]], u:filtered[0], g:parseInt(partyLow).toString()+'-'+parseInt(partyHigh).toString() };
                      remove(ref(realtimeRef, `b/${businessId}/p/q/r/${filtered[0]}`)).then( () => {}).catch( (error) => {setErrorMsg(error+'')});
                      return toUpdate;
                    }
                    return customer;
                  }).then( () => {}).catch( (error) => {setErrorMsg(error+'')}).finally(() => { setLocalLoading(false) });
                }
              };
              setLocalLoading(false);
            }
          }> <FormattedMessage id='2.Get' /> </Button>
          </Modal.Footer>
        </Modal>
      )
    
    case 'add':
      return(
          <Modal
          show={true}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          centered
        >
          <Modal.Header closeButton={hasClose} >
            <Modal.Title> <FormattedMessage id='2.Add walk in customer' /> </Modal.Title>
          </Modal.Header>
          <Modal.Body>

          <h5><FormattedMessage id='2.Name of customer:' /></h5>
          <p>
            <input style={{display: 'inline-block'}} name="customerName" type="text" value={input.customerName}
                      onChange={onInputChange} autoFocus/>
          </p>
          <label> <FormattedMessage id='2.Number of people in group:' /> </label>
            <div>
            <span style={{display: 'inline-block'}} className = 'm-2 px-2 border border-secondary rounded' onClick={
              () => setInput({ ...input, num: parseInt(input.num) <=1 ? '1': (parseInt(input.num)-1).toString() })}> - </span>
            <input style={{display: 'inline-block', textAlign: 'center'}} name="num" type="text" size="1" required value={input.num}
                    readOnly onChange={onInputChange}/>

            <span style={{display: 'inline-block'}} className = 'm-2 px-2 border border-secondary rounded' onClick={
              () => setInput({ ...input, num: parseInt(input.num) >= 9 ? '9+': (parseInt(input.num)+1).toString() })}> + </span>
            </div>
          <p> <FormattedMessage id='2.Note: The customer cannot be modified once added.' /> </p>
          </Modal.Body>
          <Modal.Footer>
          {errorMsg && <p className='text-danger'>{ errorMsg }</p>}
          <Button className="rounded" variant="success" onBlur={ () => setErrorMsg('')}
               disabled = {localLoading} onClick={
                () => {
                  setLocalLoading(true);
                  const toUpdate = { 
                      t: serverTimestamp(),
                      b: 0,
                      s: parseInt(input.num).toString()
                  };
                  const nameId = '@' + input.customerName;
                  if ( realtime?.q?.r?.[nameId]){
                    setErrorMsg(<FormattedMessage id='2.Name already in queue, please pick another' />);
                    setLocalLoading(false);
                  } else if (!input.customerName){
                    setErrorMsg(<FormattedMessage id='2.Please enter a name' />);
                    setLocalLoading(false);
                  } else {
                    update( ref(realtimeRef, `b/${businessId}/p/q/r/${nameId}`), toUpdate ).then(
                      () => {
                        handleClose(); 
                        }
                    ).catch(
                      (error) =>{ 
                        addToast({type:"Error", message:error.code}) ;
                        console.log(error);
                      }
                    ).finally(() => setLocalLoading(false));
                  }
                }
              }>
                  <FormattedMessage id='2.Add' />
              </Button>
          </Modal.Footer>
        </Modal>
      )
    
    case 'purge':
      return(
          <Modal
          show={true}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          centered
        >

          <form onSubmit={ (event) =>  {
              event.preventDefault();
              setLocalLoading(true);
              if ( userState === 'a' && realtimeRef !== null){
                const purged = realtime.q.r;
                remove( ref(realtimeRef, `b/${businessId}/p/q/r`) ).then(
                  () => {
                    for (let [key, value] of Object.entries(purged)) {
                      push(ref(realtimeRef, `b/${businessId}/l`), { u:key, t:value?.t, o:serverTimestamp(), b:value?.b, s:value?.s, m:'p', e:userEmail} );
                    };
                    handleClose();
                  }
                ).catch((error) => {addToast({type:"Error", message:error+''})})
              }
              setLocalLoading(false);
              handleClose();
            }} >
            <Modal.Header closeButton={hasClose} >
              <Modal.Title> <FormattedMessage id='2.Purge the queue' /> </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p> <FormattedMessage id='2.Remove everyone in queue, this action is irreversible.' /> </p>

            <label>
              <input type='checkbox' name="confirm" required/> <FormattedMessage id='2.Confirm remove.' />
            </label>

            </Modal.Body>
            <Modal.Footer>
              <Button className="rounded" variant="outline-danger"
              type="submit" disabled = {localLoading}>
                  <FormattedMessage id='2.Remove' />
              </Button>
                
            </Modal.Footer>
          </form>
        </Modal>
      )

    case 'admin':
      return(
          <Modal
          show={true}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          centered
        >
          <form onSubmit={ (event) =>  {
            event.preventDefault();
            setLocalLoading(true);
            if ( userState === 'a' && realtimeRef !== null){
              runTransaction( ref(realtimeRef, `b/${businessId}/p/q/f`), (customer) => {
                if (customer){
                  push(ref(realtimeRef, `b/${businessId}/l`), {  u:customer?.u, b:customer?.b, s:customer?.s, m:'r', t: customer?.t , o: serverTimestamp()} );
                  return null;
                }
                return customer;
              }).then( () => {}).catch( (error) => {setErrorMsg(error+'')}).finally(() => { setLocalLoading(false) });
            } else {
              setLocalLoading(false);
            }
            handleClose();
          }} >
          <Modal.Header closeButton={hasClose} >
            <Modal.Title> <FormattedMessage id='2.Next in the queue' /> </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h5><FormattedMessage id='2.Time' /></h5>
            <p> {sinceLast !==null && tsMinuteFormat(now - sinceLast - serverOffset)} </p>
            {realtime?.q?.f?.l && <p><FormattedMessage id='2.Customer offline for' /> {`${now - realtime?.q?.f?.l - serverOffset}`} </p>}
            
            {realtime?.q?.f?.u.charAt(0) === '@' && 
            <><h5><FormattedMessage id='2.Walk up customer name:' /></h5> 
            <p>{`${realtime?.q?.f?.u.slice(1)}`}</p></>}

            <h5><FormattedMessage id='2.Customer bid:' /></h5>
            <Row className = "py-2" > 
              <Col xs={7}> <FormattedMessage id='2.Wait time + bid (min)' /> </Col>
              <Col xs={3} className='text-center'> <span className="bg-light">👤</span> x? </Col>
              <Col xs={2} className='text-end'> <FormattedMessage id='2.Status' /> </Col>
            </Row>
            {realtime?.q?.f && <Row  className = {`py-2`} >
              <Col xs={7}> 
                  {tsMinuteFormat(now - realtime.q.f.t + realtime.q.f.b*60000)} = {tsMinuteFormat(now - realtime.q.f.t )} + {realtime.q.f.b}
              </Col>
              <Col xs={3} className='text-center'> {realtime.q.f.s} </Col>
              <Col xs={2} className='text-end'> {realtime.q.f.u.charAt(0) ==='@' ? '🟣' : (realtime.q.f.l ? '🔴':'🟢') } </Col>
            </Row>}

            <h5><FormattedMessage id="2.Enter the customer's PIN:" /> </h5>
            
            <PinInput 
              length={4} 
              initialValue="" 
              onChange={(value, _index) =>  setInput( { ...input, pin:value}  )  } 
              type="numeric" 
              inputMode="number"
              style={{padding: '10px', display:'flex', justifyContent:'center'}}  
              inputStyle={{borderColor: 'purple'}}
              inputFocusStyle={{borderColor: 'green'}}
              onComplete={(value, index) => {}}
              autoSelect={true}
              focus={true}
              regexCriteria={/^[0-9]*$/}
            />

          <label>
              <input type='checkbox' name="confirm" required/> <FormattedMessage id='2.Confirm remove.' />
            </label>
          { errorMsg && <p className='text-danger'>{ errorMsg }</p>}
          </Modal.Body>
          <Modal.Footer>
          <Button className="rounded" variant="outline-danger" onBlur={ () => setErrorMsg('')}
              disabled = {localLoading} onClick={ () => {
                if ( userState === 'a' && realtimeRef !== null) {
                  runTransaction( ref(realtimeRef, `b/${businessId}/p/q/f`), (customer) => {
                    if (customer){
                      push( ref(realtimeRef, `b/${businessId}/l`),{ u:customer?.u, t:customer?.t, o:serverTimestamp(), b:customer?.b, s:customer?.s, m:'r', e:userEmail });
                      return null;
                    }
                    return customer;
                }).then( () => {} ).catch( (error) => {setErrorMsg(error+'')});
                };
              }}>
                  <FormattedMessage id='2.Remove' />
          </Button>
          <Button className="rounded" variant="outline-warning" onBlur={ () => setErrorMsg('')}
              disabled = {localLoading} onClick={ () => {
                if ( userState === 'a' && realtimeRef !== null){
                  runTransaction( ref(realtimeRef, `b/${businessId}/p/q/f`), (customer) => {
                    if (customer){
                      const customerUid = customer.u;
                      const customerG = customer.g.split('-');
                      delete customer.u;
                      delete customer.g;
                      set( ref(realtimeRef, `b/${businessId}/p/q/r/${customerUid}`),{ ...customer });

                      const filtered = realtime?.q?.r && Object.keys(realtime.q.r).sort((a,b) => {
                        if (!realtime.q.r[a].t){
                          return -1;
                        } else if (!realtime.q.r[b].t) {
                          return 1;
                        } else {
                          return (realtime.q.r[a].t/60000 - realtime.q.r[a].b) - (realtime.q.r[b].t/60000 - realtime.q.r[b].b);
                        }
                        }).filter((key) => {
                        return (realtime.q.r[key].s >= parseInt(customerG[0]).toString() &&  realtime.q.r[key].s <= parseInt(customerG[1]).toString());
                        });
        
                      if ( !filtered || filtered.length < 1) {
                        setErrorMsg('No one in queue match criteria');
                        return null;
                      } else {
                          const toUpdate = { ...realtime.q.r[filtered[0]], u:filtered[0], g:parseInt(partyLow).toString()+'-'+parseInt(partyHigh).toString() };
                          return toUpdate;
                      };
                    }
                    return customer;
                }).then( (result) => {
                  if( result.committed && result.snapshot){
                    remove(ref(realtimeRef, `b/${businessId}/p/q/r/${result.snapshot.u}`)).then( () => {}).catch( (error) => {setErrorMsg(error+'')});
                  }
                 }).catch( (error) => {setErrorMsg(error+'')});
                };
              }
              }>
                  <FormattedMessage id='2.Next' />
          </Button>
          <Button className="rounded" variant="outline-info" onBlur={ () => setErrorMsg('')}
              disabled = {localLoading} onClick={ () => {
                if ( userState === 'a' && realtimeRef !== null) {
                  runTransaction( ref(realtimeRef, `b/${businessId}/p/q/f`), (customer) => {
                    if (customer){
                      const cusomerUid = customer.u;
                      delete customer.u;
                      delete customer.g;
                      set( ref(realtimeRef, `b/${businessId}/p/q/r/${cusomerUid}`),{ ...customer });
                      return null;
                    }
                    return customer;
                }).then( () => {} ).catch( (error) => {setErrorMsg(error+'')});
                };
              }
              }>
                  <FormattedMessage id='2.Close' />
          </Button>
          <Button className="rounded" variant="outline-success" onBlur={ () => setErrorMsg('')}
              disabled = {localLoading} onClick={ () => {
                if ( userState === 'a' && realtimeRef !== null){
                  const customer = realtime?.q?.f;
                  const data = { customerId:customer.u , businessID: businessId, pin:input.pin };
                  setLocalLoading(true);
                  getQueueCustomer( data ).then( (result) => {
                    if (result.data.status === 'ok'){
                      const msg = <FormattedMessage id='2.Next in the queue is a group of' />;
                      addToast({type:"Success", message: `${msg} ${result.data.party}`});
                    }
                  }).catch( (error) => {setErrorMsg(error+'')}).finally(setLocalLoading(false));
                }}
              }>
                  <FormattedMessage id='2.Submit' />
          </Button>
          </Modal.Footer>
          </form>
        </Modal>
      )
    case 'user':
      return(
          <Modal
          show={true}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          centered
        >
          <form onSubmit={ (event) =>  {
              event.preventDefault();
              setLocalLoading(true);
              setJustleft(true);
              if ( userState === 'i' && realtimeRef !== null) {
                const inQueue = realtime?.q?.f;
                remove( ref(realtimeRef, `b/${businessId}/p/q/f`) ).then(
                  () => {
                    push(ref(realtimeRef, `b/${businessId}/l`), {  u:uid, m:'d', t:inQueue.t, o:serverTimestamp(), s:inQueue.s, b:inQueue.b } );
                    if (dcRealtimeRef.current){
                      dcRealtimeRef.current.cancel();
                    }
                  }
                ).finally(() => {handleClose();})
              } else {
                handleClose();
              }
              setLocalLoading(false);
            }} >
          <Modal.Header closeButton={hasClose} >
            <Modal.Title><FormattedMessage id='2.Congratulations, you are next in the queue' />  </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h5><FormattedMessage id='2.Your PIN' /></h5>
            <p><FormattedMessage id='2.Show your PIN to the queue administrator.' /></p>
          <PinInput 
              length={4} 
              initialValue={`${( 10000 + firestore?.j?.p + '' ).slice(1)}`} 
              type="numeric" 
              inputMode="number"
              style={{padding: '10px', display:'flex'}}  
              inputStyle={{borderColor: 'purple'}}
              inputFocusStyle={{borderColor: 'green'}}
              autoSelect={true}
              focus={true}
              disabled={true}
              regexCriteria={/^[0-9]*$/}
            />
            <label>
              <input type='checkbox' name="confirm" required/> <FormattedMessage id='2.Confirm leave.' />
            </label>
          </Modal.Body>
          <Modal.Footer>
          <span><FormattedMessage id='2.If you are no longer available:' /></span>
          <Button className="rounded" disabled={localLoading} variant="danger" type="submit" >
            <FormattedMessage id='2.Leave' />
            </Button>
          </Modal.Footer>
          </form>
        </Modal>
      )
      case 'home':
        return(
            <Modal
            show={true}
            onHide={handleClose}
            backdrop={true}
            keyboard={false}
            centered
          >
            <Modal.Header closeButton={hasClose} >
              <Modal.Title> <FormattedMessage id='2.Go to homepage' /> </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <FormattedMessage id='2.Open in a new window' />
            </Modal.Body>
            <Modal.Footer>
            <Button className="rounded" variant="outline-danger"
              disabled = {localLoading} onClick={ () => {
                  handleClose();
                }
              }>
                  <FormattedMessage id='2.Close' />
              </Button>
            <Button className="rounded" variant="outline-success"
              disabled = {localLoading} onClick={ () => {
                  window.open( homeUrl, '_blank', 'noopener noreferrer');
                  handleClose();
                }
              }>
                  <FormattedMessage id='2.Go' />
              </Button>
            </Modal.Footer>
          </Modal>
        )
    default:
      return (<></>);
  }

}

