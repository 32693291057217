
import { useState, useEffect } from "react";

import {
    useOutletContext,
    useParams,
    Link
} from "react-router-dom";

import { ref, getDatabase, get } from "firebase/database";
import { getApp  } from "firebase/app";
import { getRealtime } from '../GetRealtime';
import { FormattedMessage } from "react-intl";
import Button from 'react-bootstrap/Button';


export default function QueueInfo() {

    const { activeBusinessId } = useOutletContext();

    let { businessId="" } = useParams();
  
    const [ realtime, setRealtime] = useState(null);
    const [ now, setNow ] = useState(Date.now());
    
    useEffect(() => {
        const interval = setInterval(() => {
            setNow(Date.now());
        }, 1000);
        return () => clearInterval(interval);
      }, []);

    useEffect(() => {
        if (activeBusinessId === businessId) {
        const databaseURL = getRealtime(businessId);
        const realtimeRef = getDatabase(getApp(databaseURL));
        get(ref(realtimeRef, `b/${businessId}/o`)).then( (snapshot) => {
            setRealtime(snapshot.val());
        }).catch((error)=>{console.log(error+'')});
        }
    },[ activeBusinessId, businessId ]);

    function secondsToString(seconds) {
        if (seconds < 0){
            return <span className="text-danger"> Expired </span>;
        };
        const numdays = Math.floor( seconds / 86400); 
        const numhours = Math.floor(( seconds % 86400) / 3600);
        const numminutes = Math.floor((( seconds % 86400) % 3600) / 60);
        const numseconds =  Math.floor((( seconds % 86400) % 3600) % 60);
        return numdays + (numdays === 1 ? " day " : " days ") + numhours + (numhours === 1 ? " hour " : " hours ") + 
            numminutes + (numminutes === 1 ? " minute " : " minutes ") + numseconds + (numseconds === 1 ? " second " : " seconds ");
    }

    const created  = realtime?.created && (new Date(realtime?.created*1000));
    const expiration  = realtime?.expiration && new Date(realtime?.expiration*1000);
    const revenue =  realtime?.revenue && Object.entries(realtime?.revenue).sort( (a,b)=> b[0].localeCompare(a[0]) );
    const stats = realtime?.stats && Object.entries(realtime?.stats).sort( (a,b)=> b[0].localeCompare(a[0]) );

    return (
        <div className="p-3">
            <h3> <FormattedMessage id='4.Queue Info:' />  </h3>
            <p> <Link to={`/q/${businessId}`}> <FormattedMessage id='4.Go to queue' /></Link></p>
            <p> <Link to="queuedetails" > <FormattedMessage id='4.Edit queue' /></Link></p>
            <p> <Link to="qrcode" > QRcode </Link> </p>
            <h5> <FormattedMessage id='4.Revenue:' /> </h5>
            <p> <FormattedMessage id='4.Revenue this month:' /> { revenue?.[0]?.[1] && (revenue[0][1]*0.9/100).toFixed(2) }</p>
            <p> <FormattedMessage id='4.Revenue last month:' /> { revenue?.[1]?.[1] && (revenue[0][1]*0.9/100).toFixed(2) }</p>
            <h5><FormattedMessage id='4.Queue created:' /> </h5>
            <p>{created && created.toString().split('(')[0]}</p>
            <h5><FormattedMessage id='4.Queue expires:' /> </h5>
            <p>{expiration && expiration.toString().split('(')[0]}</p>
            <p>{expiration && secondsToString(realtime?.expiration - now/1000)} </p>
            <h5><FormattedMessage id='4.Referrer:' /></h5>
            <p>{realtime?.referrer}</p>
            <h5><FormattedMessage id='4.Premium account:' /></h5>
            <p>{realtime?.premium + '' }</p>
            <h5> <FormattedMessage id='4.Premium data:' /> </h5>
            <p> <FormattedMessage id='4.Queue stat from 1 day ago:' /> </p>
            <p> { stats?.[0] && stats[0] } </p>
            <p> <FormattedMessage id='4.Queue stat from 2 days ago:' /> </p>
            <p> { stats?.[1] && stats[1] } </p>
            <p> <FormattedMessage id='4.Download last month records:' /> </p>
            { realtime?.premium && <Button> <FormattedMessage id='4.Download' /></Button> }
        </div>
    )

}