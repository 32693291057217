import { useState } from "react";
import {
    useNavigate,
    useLocation,
    Link,
    useOutletContext,    
  } from "react-router-dom";

import { signInWithEmailAndPassword, GoogleAuthProvider, FacebookAuthProvider, TwitterAuthProvider, OAuthProvider,
  signInWithRedirect, getRedirectResult, linkWithRedirect  } from "firebase/auth";

import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container'

import { GoogleLoginButton, FacebookLoginButton, TwitterLoginButton, YahooLoginButton  } from "react-social-login-buttons";

import { useAuth } from '../AuthContent';

import { termsUrl, privacyUrl } from '../../App';
import { FormattedMessage } from "react-intl";


export default function Login( ) {
    const navigate = useNavigate();
    const location = useLocation();
    const {auth, currentUser} = useAuth();
    const { addToast, loading } = useOutletContext();


    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    
    // get from out of path name, if not then local storage, finally home page by default
    const from = location.state?.from?.pathname || ( sessionStorage['from'] ||  "/" );

    sessionStorage.setItem('from',from);
  
    const google_provider = new GoogleAuthProvider();
    const facebook_provider = new FacebookAuthProvider();
    const twitter_provider = new TwitterAuthProvider();
    const yahoo_provider = new OAuthProvider('yahoo.com');


    facebook_provider.addScope('email');

  
    function handleSubmit(event) {
      event.preventDefault();
        
      signInWithEmailAndPassword(auth,email, password)
      .then(( _userCredential ) => {
        // Signed in 
        // Send them back to the page they tried to visit when they were
        // redirected to the login page. Use { replace: true } so we don't create
        // another entry in the history stack for the login page.  This means that
        // when they get to the protected page and click the back button, they
        // won't end up back on the login page, which is also really nice for the
        // user experience.
        navigate(from, { replace: true });
      }).catch((error) => {
        addToast({ type:"Error", message:error+'' });
      });

    }

    // redirect result is ignored, login is completed via checking auth for changes
    getRedirectResult(auth)
    .then((result) => {
      if (result){
        navigate(from, { replace: true });
      }
    }).catch((error) => {
      addToast({ type:"Error", message:error+'' });
    });


    return (
      <Container>  
        <h3 className="py-3"><FormattedMessage id='5.Login' /></h3>

        <form onSubmit={handleSubmit}>
          <p>
            <label> <FormattedMessage id='5.Email:' /> </label>
            <input name="email" type="text" required value={email}
                        onChange={(e) => setEmail(e.target.value)} autoFocus/>
          </p>
          <p>
            <label> <FormattedMessage id='5.Password:' /> </label>
            <input name="password" type="password" required value={password}
                        onChange={(e) => setPassword(e.target.value)} />
          </p>
          <Button className="rounded" variant="primary" type="submit" disabled = {loading}>
            <FormattedMessage id='5.Submit' />
          </Button>
        </form>


          
        <div  className="pt-3">
        <p>
          <FormattedMessage id="5.Don't have an account?" />
          &nbsp;
          <Link to={{pathname:"/create", state:{from} }}><FormattedMessage id='5.Click here' /></Link>
          &nbsp;
          <FormattedMessage id='5.to create.' /> 
        </p>
        <p>
          <FormattedMessage id='5.Forgot password?' /> <Link to={{pathname:"/reset", state:{from} }}>
          <FormattedMessage id='5.Click here' /></Link> <FormattedMessage id='5.to reset.' /> 
        </p>
        </div>

        <div className="pb-2"><GoogleLoginButton onClick={() =>  {
          if (currentUser){
          linkWithRedirect(currentUser, google_provider)
            .then(/* ... */)
            .catch(/* ... */);
          } else {
            signInWithRedirect(auth, google_provider);
          }
          }} disabled = {loading}><span><FormattedMessage id='5.Login with ' />Google</span></GoogleLoginButton></div>

          <div className="pb-2"><TwitterLoginButton onClick={() =>  {
          if (currentUser){
            linkWithRedirect(currentUser, twitter_provider)
              .then(/* ... */)
              .catch(/* ... */);
            } else {
              signInWithRedirect(auth, twitter_provider);
            }
          }} disabled = {loading}><span><FormattedMessage id='5.Login with ' />Twitter</span></TwitterLoginButton></div>

        <div className="pb-2"><FacebookLoginButton onClick={() =>  {
          if (currentUser){
            linkWithRedirect(currentUser, facebook_provider)
              .then(/* ... */)
              .catch(/* ... */);
            } else {
              signInWithRedirect(auth, facebook_provider);
            }
          }} disabled = {loading}><span><FormattedMessage id='5.Login with ' />Facebook</span></FacebookLoginButton></div>
        
        <div className="pb-2"><YahooLoginButton onClick={() =>  {
          if (currentUser){
            linkWithRedirect(currentUser, yahoo_provider)
              .then(/* ... */)
              .catch(/* ... */);
            } else {
              signInWithRedirect(auth, yahoo_provider);
            }
          }} disabled = {loading}><span><FormattedMessage id='5.Login with ' />Yahoo</span></YahooLoginButton></div>
        
        <div className="py-3">
        <FormattedMessage id='5.By continuing, you are indicating that you accept our' />
        &nbsp;
        <a href={privacyUrl} target="_blank" rel="noreferrer"><FormattedMessage id='5.privacy policy' /></a>
        &nbsp;
        <FormattedMessage id='5.and' />
        &nbsp;
        <a href={termsUrl} target="_blank" rel="noreferrer"><FormattedMessage id='5.terms of service' /></a>.
        </div>

      </Container>
    );
  }