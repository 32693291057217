
import { useAuth } from '../AuthContent';
import { deleteUser   } from "firebase/auth";

import Button from 'react-bootstrap/Button';

import {
        useOutletContext,
} from "react-router-dom";

import { FormattedMessage } from "react-intl";


export default function DeleteAccount() {
    const { currentUser } = useAuth();
    const { addToast } = useOutletContext();


    //console.log(window.location.origin+from);
  
    function handleSubmit(event) {
        event.preventDefault();
        deleteUser(currentUser).then(() => {
            // User deleted.
          }).catch((error) => {
            addToast({ type:"Error", message:error.code });
          });


    }
  

    return (
    <div className="p-3">
        <h3><FormattedMessage id='4.Delete account' /></h3>
  
        <form onSubmit={handleSubmit}>
          <p> <FormattedMessage id="4.Note: if it's been a while since logged in, you might have to log out first before deleting the account." /></p>

            <p>
            <label>
            <input type='checkbox' name="confirm" required/> <FormattedMessage id='4.I understand that deleting this account is a permanent action, and all associated data including tokens and subscriptions will be deleted as well.' />
            </label>
            </p>

            <Button className="rounded" variant="primary" type="submit">
            <FormattedMessage id='4.Submit' />
            </Button>
        </form>
    </div>
    );
    
  }