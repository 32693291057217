import { baseUrl } from '../../App';

import { useState, useEffect } from "react";

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';


import { getApp  } from "firebase/app";
import { httpsCallable, getFunctions  } from "firebase/functions";

import Papa from "papaparse";

import {
    useOutletContext,
  } from "react-router-dom";


import { getCurrency } from '../i18n/messages';
import { FormattedMessage } from "react-intl";

import { termsUrl } from '../../App';


export default function CreateQueue() {
    const { addToast, firestore, loading, setLoading } = useOutletContext();

    const [ prices, setPrices ] = useState([]);
    const [ currency, setCurrency ] = useState(getCurrency( navigator.language || navigator.userLanguage ));

    // createSessions function lives in us-central1 defualt zone
    const createSession = httpsCallable( getFunctions(getApp()), 'createSession');


    useEffect( () => {
        fetch(`/prices.${process.env.REACT_APP_ENV}.csv`).then(
            (r) => r.text()
        ).then(
            (r) =>
            Papa.parse(r, {
                header:true,
                complete: (results) => {
                    setPrices(results.data)
                }
            })
        );
    },[]);

    const [input, setInput] = useState({
        name: '',
        add1: '',
        add2: '',
        country: 'us',
        phone: '',
        referrer: '',
        promotion: ''
    });

    const onInputChange = e => {
        const { name, value } = e.target;

        setInput(prev => ({
            ...prev,
            [name]: value
        }));
        
    }

    function handleSubmit(event) {
        event.preventDefault();
        
        const { promotion, ...metadata } = input;
        let data;

        if (metadata.referrer === ''){
            metadata.referrer = 'NA';
        }

        if (promotion === '') {
            data = {
                success_url : baseUrl + '/settings' , 
                cancel_url : baseUrl + '/settings' , 
                customer: firestore.stripeId, 
                priceId : event.nativeEvent.submitter.name, 
                metadata : metadata,
            };
        } else {
            data = {
                success_url : baseUrl + '/settings' , 
                cancel_url : baseUrl + '/settings' , 
                customer: firestore.stripeId, 
                priceId : event.nativeEvent.submitter.name, 
                metadata : metadata,
                allow_promotion_codes : true,
                discounts: { promotion_code: promotion },
            };
        }
        
        setLoading(true);
        createSession( data ).then((result) => {
            setLoading(false);
            if (result.data.url){
                window.location.replace( result.data.url );
            } else {
                addToast( {type:"Error", message:result} );
            }
        }).catch((error) => {
            setLoading(false);
            addToast( {type:"Error", message:error.code} );
    })
  
    };

    return (
        <div className="p-3">
        <h3><FormattedMessage id='4.Create a new queue' /></h3>
  
        <form onSubmit={handleSubmit}>
            <p>
            <label>
            <FormattedMessage id='4.Name of the business:' /> <input name="name" type="text" required value={input.name}
                        onChange={onInputChange} placeholder="Required" autoFocus />
            </label>
            </p>
            <p>
            <label>
            <FormattedMessage id='4.Address line 1:' /> <input name="add1" type="text" required value={input.add1}
                        onChange={onInputChange}  placeholder="Required" />
            </label>
            </p>
            <p>
            <label>
            <FormattedMessage id='4.Address line 2:' /> <input name="add2" type="text" required value={input.add2}
                        onChange={onInputChange} placeholder="Required" />
            </label>
            </p>
            <p>
            <label>
            <FormattedMessage id='4.Country:' /> <select name="country"  required value={input.country}
                        onChange={onInputChange} >
                            <option key='us' value='us'><FormattedMessage id='4.United States' /></option>
                            {/*<option key='ca' value='ca'>Canada</option>*/}
                        </select>
            </label>
            </p>
            <p>
            <label>
            <FormattedMessage id='4.Phone number:' /> <input name="phone" type="text" required value={input.phone}
                        onChange={onInputChange} placeholder="Required" />
            </label>
            </p>
            <p>
            <label>
            <FormattedMessage id='4.Promotion code:' /> <input name="promotion" type="text"  value={input.promotion}
                        onChange={onInputChange} placeholder="Optional" />
            </label>
            </p>
            <p>
            <label>
            <FormattedMessage id='4.Referrer email*:' /> <input name="referrer" type="text"  value={input.referrer}
                        onChange={onInputChange} placeholder="Optional" />
            </label>
            </p>
            <p>
            <FormattedMessage id='4.*Note: referrer receives' /> <b>5%</b> <FormattedMessage id="4.of customers' bid value for the first year at no additional cost to you. You always receive" /> <b>90%</b> <FormattedMessage id='4.of the bid value.' />
            </p>

            

            <p>
            {'Currency: '}
            <select onChange={(e) => {
              setCurrency(e.target.value);
              }} value={currency}>
              {[ 'usd' ].map((name) => (
                <option key={name} value={name}>
                   {name}
                </option>
              ))}
            </select>
            </p>

            <p>
            <label>
            <input type='checkbox' name="confirm" required/> <FormattedMessage id='4.I have read and agree to the' /> <a href={termsUrl} target="_blank" rel="noreferrer"><FormattedMessage id='4.terms of service' /></a>.
            </label>
            </p>

            <h5><FormattedMessage id='4.Select subscription type:' /></h5>

            <Row>
            { prices.map( (obj) =>(
                 (obj['Currency'] === currency  && obj['Product Name'].endsWith('Subscription')) && 
                 (
                <Col  key={obj['Price ID']} >
                 <Button className="rounded" variant="primary" type="submit" disabled = {loading} key={obj['Price ID']} name={obj['Price ID']}>
                                 {`${obj['Product Name'].slice(0,-13)}`}
                                 </Button>
                    <div> {obj['Amount']/100} {obj['Currency']}{obj['Product Name'].startsWith('Popup') ? '/wk' : '/mo' } </div>

                </Col>)
               
            )) }
            </Row>
            
        </form>
    </div>
    );
}

