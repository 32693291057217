import { getAuth, signOut } from "firebase/auth";
import { getApps, getApp  } from "firebase/app";
import { httpsCallable, getFunctions } from "firebase/functions";


import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';
import Table from 'react-bootstrap/Table';

import {
    useNavigate,
    useOutletContext,
    Link,
} from "react-router-dom";

import { useAuth } from '../AuthContent';

import { baseUrl } from '../../App';
import { FormattedMessage } from "react-intl";

export default function Settings () {

    const { addToast, firestore, dcFirestoreRef, loading, setLoading } = useOutletContext();
    const { currentUser } = useAuth();

    const navigate = useNavigate();

    const functions = getFunctions(getApp(), 'northamerica-northeast1');    
    const getCustomerLink = httpsCallable(functions, 'getCustomerLink');
    const getAccountLink = httpsCallable(functions, 'getAccountLink');

    return(
        <>
        <Stack direction="horizontal" gap={3} className="p-3">
                <div style={{ overflowX:"auto" }}>
                    <h3><FormattedMessage id='4.Settings' /></h3>
                    <p><FormattedMessage id='4.Welcome' /> {`${currentUser.email}`}!</p>
                </div>

                <div className='ms-auto'>
                    <Button className="rounded" variant="secondary"
                    disabled = {loading}
                    onClick={() => {
                        setLoading(true);
                        
                        dcFirestoreRef.current && dcFirestoreRef.current();
                        Promise.all( 
                            getApps().map( async (x) => {
                                signOut(getAuth(x)).catch((error) => {
                                    addToast( {type:"Error", message:error.code} );
                                });
                            })
                        );
                        setLoading(false);
                        navigate("/");
                    }}
                    >
                    <FormattedMessage id='4.Logout' />
                    </Button>
                </div>
        </Stack>
        <Stack className="p-3" gap={3} >
            <div>
                <h5><FormattedMessage id='4.Account' /></h5>
                <p><Link to="/settings/transactions"> <FormattedMessage id='4.Transactions' /> </Link></p>
                {currentUser.providerData.filter( (x)=> x?.providerId === "password" ).length === 1 &&
                    <p><Link to="/settings/changePass"><FormattedMessage id='4.Change password' /></Link></p>
                }
                <p><Link to="/settings/deleteAccount"><FormattedMessage id='4.Delete account' /></Link></p>
                    
                <p><FormattedMessage id='4.Manage invoice and subscription through Stripe:' />  </p>
                    
                    <p>
                        <Button className="rounded" variant="primary"
                        disabled = {loading}
                        onClick={() => {
                            setLoading(true);
                            getCustomerLink({ returnUrl: baseUrl + '/settings' }).then((result) => {
                                setLoading(false);
                                if (result.data.url){
                                    window.location.replace( result.data.url );
                                } else {
                                    addToast( {type:"Error", message:result} );
                                }
                            }).catch((error) => {
                                setLoading(false);
                                addToast( {type:"Error", message:error.code} );
                        })
                        }}
                        >
                        <FormattedMessage id='4.Stripe Customer' />
                        </Button>
                    </p>
            </div>

        </Stack>

        <Stack className="p-3" gap={3}>
            <div>
                <h5><FormattedMessage id='4.Business owner' /></h5>

                { firestore?.stripeAccount?.payouts_enabled &&
                    <Button className="rounded my-3" variant="primary"
                        disabled = {loading}
                        onClick={() => {
                            window.open( 'https://dashboard.stripe.com/' );
                        }}>
                        <FormattedMessage id='4.Stripe Account' />
                        </Button>
                

                }

                { firestore?.s &&
                <Table bordered >
                    <thead>
                        <tr>
                            <th><FormattedMessage id='4.Queue Name' /></th>
                            <th><FormattedMessage id='4.Details' /></th>
                        </tr>
                    </thead>
                    <tbody >
                    {Object.keys(firestore.s).sort((a,b) => { return firestore.s[a].localeCompare(firestore.s[b]) } ).map( (key) => 
                        <tr key={key}  >
                            <td>{firestore.s[key]} </td>
                            <td>
                                <Link to={"/settings/"+key}> <FormattedMessage id='4.Edit' /> </Link>
                            </td>
                        </tr>
                    )}
                    </tbody>
                </Table>
                }

                { firestore?.stripeAccount?.payouts_enabled ?
                    <> <p>
                    <Button className="rounded" variant="primary"
                    disabled = {loading}
                    onClick={() => {
                        navigate("/settings/createQueue");
                    }}
                    >
                    <FormattedMessage id='4.Create New Queue' />
                    </Button>
                </p></>
                    : 
                    <>
                        <p><FormattedMessage id='4.To add a new queue, first create a Stripe connected account:' /></p>
                        <p>
                            <Button className="rounded" variant="primary"
                            disabled = {loading}
                            onClick={() => {
                                setLoading(true);
                                getAccountLink({
                                    returnUrl: baseUrl + '/settings',
                                    refreshUrl: baseUrl + '/settings', 
                                    }).then((result) => {
                                    setLoading(false);
                                    if (result.data.url){
                                        window.location.replace( result.data.url );
                                    } else {
                                        addToast( {type:"Error", message:result.data} );
                                    }
                                }).catch((error) => {
                                    setLoading(false);
                                    addToast( {type:"Error", message:error.code} );
                            })
                            }}
                            >
                            <FormattedMessage id='4.Create Stripe Account' />
                            </Button>
                        </p>
                        
                        { firestore?.stripeAccount?.requirements?.past_due &&
                        <>
                            <FormattedMessage id='4.More info needed with registration:' />

                            <p style={{overflowX:"auto"}} className='text-danger'>
                            {firestore.stripeAccount.requirements.past_due.toString()}
                            </p>
                         </>
                        }
                        
                    </>
                }
                    
            </div>

        </Stack>
        </>
                
    )
}