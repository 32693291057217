import { useState } from "react";

import { useAuth } from '../AuthContent';
import { sendEmailVerification  } from "firebase/auth";
import {
    useLocation,
  } from "react-router-dom";

import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container'
import { FormattedMessage } from "react-intl";


export default function Verify() {
    const [ sent, setSent] = useState(false);
    const { currentUser } = useAuth();
    
    const location = useLocation();
    const from = location.state?.from?.pathname || "/";
    const actionCodeSettings = {
        url: window.location.origin+from
    }

    //console.log(window.location.origin+from);
  
    function handleSubmit(event) {
      event.preventDefault();
        
      sendEmailVerification(currentUser, actionCodeSettings)
      .then(() => {
        setSent(true);
      });

    }
  

    return (
      <Container className="mx-auto">
        <p className="py-3"><FormattedMessage id='5.You must verify your email first, click below to resend verification.' /></p>

        <form onSubmit={handleSubmit}>
        <p>
            <label>
            <FormattedMessage id='5.Email:' /> <input name="email" type="text" value={currentUser.email}
                        readOnly />
            </label>
        </p>
        <Button className="rounded" variant="primary" type="submit" disabled={sent}>
          <FormattedMessage id='5.Submit' />
        </Button>
        </form>
        {sent && <p><FormattedMessage id='5.Verification sent, check your inbox.' /></p>}
    </Container>
    );
    
  }