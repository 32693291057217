import { useState, useEffect, useRef } from 'react';

import {
    Outlet,
    useNavigate,
    useLocation,
    useSearchParams,
    useParams
} from "react-router-dom";

import { getRealtime } from './GetRealtime';
import { useAuth, firebaseConfig } from './AuthContent';
import { addModal } from './Modals';


import { initializeApp, getApp, getApps  } from "firebase/app";
import { getAuth, signInWithCustomToken } from "firebase/auth";
import { httpsCallable, getFunctions } from 'firebase/functions';


import Toast  from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import Stack from 'react-bootstrap/Stack';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';


import { doc, onSnapshot  } from "firebase/firestore";
import { messages } from "./i18n/messages";
import { FormattedMessage } from "react-intl";

import { maxWidth } from '../App';

export default function Layout({currentLocale, setCurrentLocale}) {

  const { uid, auth, appFirestore } = useAuth();

  const [toasts, setToasts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modals, setModals] = useState('');

  const path = useLocation().pathname;
  const [searchParams, ] = useSearchParams();

  const [firestore, setFirestore] = useState(null);
  const [activeBusinessId, setActiveBusinessId] = useState("");
  const qbd = firestore?.qbd;

  // snap state, "get" -> "wait" -> "received" (check to see if qbd is expected) -> "off"
  const [ snapInactive, setSnapInactive ] = useState('g');

  // the expected amount of qbd to have, used for unsubscribing once it happens
  const [ expectQbd, setExpectQbd ] = useState( searchParams.get('qbd') ? searchParams.get('qbd') : null);

  let { businessId="" } = useParams();

  auth.languageCode = currentLocale;


  useEffect(() => {
    const databaseURL = getRealtime(businessId);
    setLoading(true);

    if ( uid && databaseURL ) {
      if (getApps().map( x => x.options.databaseURL ).includes(databaseURL)) {
        if (getAuth(getApp(databaseURL)).currentUser){
          setActiveBusinessId(businessId);
        }
        setLoading(false);
      } else {
        const app2 = initializeApp({
            databaseURL: databaseURL,
            ...firebaseConfig
          }, databaseURL);
        
          // getToken function lives in us-central1 defualt zone

          httpsCallable( getFunctions(getApp()) , 'getToken')().then((response) => {
            signInWithCustomToken(getAuth(app2), (typeof response?.data === 'string'? response.data : response?.data?.token) ).then( () => {
              setActiveBusinessId(businessId);
              setLoading(false);
            } );

        }).catch((error) => {
            addToast( {type:"Error", message:error.code} );
            setLoading(false);
        });
      }      
    } else {
      setLoading(false);
    }
    
  },[ uid, businessId ]);

  const dcFirestoreRef = useRef();

  // start reading from firestore disconnect when read or when qbd is the same as expected
  useEffect(() => {

    if ( uid && snapInactive==='g' ){
      const docRef = doc( appFirestore, "u", uid);
      const unsub = onSnapshot(docRef , (docSnap) => {
        setSnapInactive('r');
        setFirestore(docSnap.data());
      });
      dcFirestoreRef.current && dcFirestoreRef.current();
      dcFirestoreRef.current = unsub;
      setSnapInactive('w');
    };
    
  }, [ snapInactive, uid, appFirestore ]);


  // detach listener if not in settings and in received state

  useEffect( () =>{

    if ( (snapInactive === 'r') && (expectQbd === null || expectQbd === qbd) && ( !path.startsWith('/settings'))) {
      setExpectQbd(null);
      setSnapInactive('o');
      dcFirestoreRef.current && dcFirestoreRef.current();
    };

  },[ snapInactive, expectQbd, qbd, path ]);

  useEffect(() => {
    return () => {
      dcFirestoreRef.current && dcFirestoreRef.current();
    };
  }, []);

  function addToast ( newToast ) {
      return setToasts( old => [ ...old, newToast ])
  }

  return (
    <div style={{maxWidth:maxWidth, display:"flex", flexDirection:"column"}} className="mx-auto" >
      <AuthStatus { ...{ uid,currentLocale, setCurrentLocale, loading, firestore, activeBusinessId, setModals } } />

      <Outlet context={ { activeBusinessId, firestore, dcFirestoreRef, addToast, loading, setLoading, modals, setModals, currentLocale, setSnapInactive, setExpectQbd } } />


      <Toasts { ...{toasts, setToasts} } />
    </div>
  );
}

function Toasts ({ toasts, setToasts }){

  //Toasts for now contains a type and a message

    function handleClose(key) {
        setToasts( old => old.filter( (_,i) => i !== key  ) );
      }
        
    return (
        <ToastContainer className="p-3" position="bottom-end" >
            { toasts.map( (d, idx) => 
            <Toast onClose={ () => handleClose(idx) } key={idx}>
            <Toast.Header closeButton={true}>
              { d.type === 'Error' ?
              <strong className="me-auto" style={{color:'red'}}><FormattedMessage id='1.Error' /></strong> :
              d.type === 'Success' ?
              <strong className="me-auto" style={{color:'green'}}><FormattedMessage id='1.Success' /></strong> :
              <strong className="me-auto" style={{color:'black'}}>{d.type}</strong>
              }
              {/*<small>11 mins ago</small>*/}
            </Toast.Header>
            <Toast.Body>{d.message}</Toast.Body>
          </Toast>
            )}
        </ToastContainer>
    )
}

function AuthStatus( { uid, currentLocale, setCurrentLocale, loading, firestore, activeBusinessId, setModals } ) {
    const navigate = useNavigate();
    const path = useLocation().pathname;

    const headingImage = () => {
          return (<div > <Image src={`/qbid.${process.env.REACT_APP_ENV}.png`}  /* onClick={() => addModal(setModals,'home') } */ /> </div>);
    };

    const langToggle = () => {
      return (<div>
      🌐{" "}
      <select onChange={(e) => {
        setCurrentLocale(e.target.value);
        localStorage.setItem("locale", e.target.value);
        }} value={currentLocale}>
        {Object.keys(messages).map((name) => (
          <option key={name} value={name}>
            {name}
          </option>
        ))}
      </select>
      </div>);
    };

    if (!uid) {
      return (
        <Stack direction="horizontal" gap={3} className='p-3 border sticky-top bg-light'>
          { headingImage() }
          { langToggle() }

          { path !== "/login" &&
          <div className = 'ms-auto' >
            <Button variant="light"
            disabled = {loading}
            onClick={() => {
                navigate('/login');
            }}
            className='border border-secondary rounded' >
              <FormattedMessage  id='1.Login' />
            </Button></div>
            }
        </Stack>
      );
    } else {
      return (
        <Stack direction="horizontal"  className='p-3 border sticky-top bg-light' gap={1}>
          { headingImage() }
          { langToggle() }

          {loading && 
            <div className='mx-auto'>
            <Spinner animation="border" role="status" variant="primary">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
            </div>
          }
          
          {!loading && 
              <Button className='align-self-end ms-auto my-auto rounded' variant='outline-warning' onClick={() => {
                if (path.startsWith('/q/')) {
                  addModal(setModals,'buy');
                } } }>
                {firestore?.qbd ? `🟡 ${(firestore.qbd/100).toFixed(2)}`  : `🟡  ${(0.00).toFixed(2)}`}
              </Button>
          }

          <div className = 'ms-auto' >
          {!path.startsWith('/settings') &&
                <Button variant="light"
                disabled = {loading}
                onClick={() => navigate("/settings")}
                className='border border-secondary rounded' >
                ⚙
                </Button>
          }
          {path.startsWith('/settings') &&
              <Button variant="light"
              disabled = {loading}
              onClick={() => {
                  navigate( path === '/settings' ? '/q/' + activeBusinessId : path.substring( 0, path.lastIndexOf('/') ) );
              }}
              className='border border-secondary rounded' >
              <FormattedMessage  id='1.Back' />
              </Button>
          }
          </div>
        </Stack>
      );
      }
  }
  