

export const getCurrency = (locale) => {
  const currencyObj = { 
    US:'usd',
  };
  return currencyObj[locale.split('-')[1]] || 'usd';
}

// <FormattedMessage id='0' />

export const messages = {
    'en': {
      '0': 'HomePage',
      '0.Welcome to Qbid': 'Welcome to Qbid',
      '0.Get into a queue by clicking this button:': 'Get into a queue by clicking this button:',
      '0.Queue': 'Queue',
      '0.Business owners:': 'Business owners:',
      '0.Setup your queue from the settings menu: ⚙': 'Setup your queue from the settings menu: ⚙',
      '1': 'Layout',
      '1.Login':'Login',
      '1.Back': 'Back',
      '1.Error': 'Error',
      '1.Success': 'Success',
      '2.Modals': 'Modals',
      '2.Join the queue': 'Join the queue',
      '2.Number of people in your group:': 'Number of people in your group:',
      '2.*By joining the queue, I agree to the': '*By joining the queue, I agree to the',
      '2.terms of service': 'terms of service',
      '2.and': 'and',
      '2.privacy policy.': 'privacy policy.',
      '2.In addition I understand I might be removed by the queue administrator for not present when called or for any other reason.': 'In addition I understand I might be removed by the queue administrator for not present when called or for any other reason.',
      '2.Join as admin': 'Join as admin',
      '2.Join': 'Join',
      '2.Leave queue': 'Leave queue',
      '2.Are you sure you would like to leave the queue? Your place will be lost.': 'Are you sure you would like to leave the queue? Your place will be lost.',
      '2.Are you sure you would like to leave as the admin?': 'Are you sure you would like to leave as the admin?',
      '2.Confirm leave.': 'Confirm leave.',
      '2.Leave': 'Leave',
      '2.Change group size': 'Change group size',
      '2.Submit': 'Submit',
      '2.Bid for your place in queue': 'Bid for your place in queue',
      '2.Rate:': 'Rate:',
      '2.Max bid:': 'Max bid:',
      '2.unlimited': 'unlimited',
      '2.Bid in minutes:': 'Bid in minutes:',
      '2.For:': 'For:',
      '2.Insufficient fund': 'Insufficient fund',
      '2.Your place:': 'Your place:',
      '2.Before:': 'Before:',
      '2.After:': 'After:',
      '2.*Your bid will only be deducted when you are called to the front of the queue, you can modify your bid at any time.': '*Your bid will only be deducted when you are called to the front of the queue, you can modify your bid at any time.',
      '2.Recharge': 'Recharge',
      '2.Bid': 'Bid',
      '2.Recharge account': 'Recharge account',
      '2.Close':'Close',
      '2.Get next in queue': 'Get next in queue',
      '2.Group size': 'Group size',
      '2.From:': 'From:',
      '2.To:':'To:',
      '2.Get': 'Get',
      '2.Add walk in customer':'Add walk in customer',
      '2.Name of customer:':  'Name of customer:',
      '2.Number of people in group:': 'Number of people in group:',
      '2.Note: The customer cannot be modified once added.': 'Note: The customer cannot be modified once added.',
      '2.Name already in queue, please pick another': 'Name already in queue, please pick another',
      '2.Please enter a name': 'Please enter a name',
      '2.Add': 'Add',
      '2.Purge the queue': 'Purge the queue',
      '2.Remove everyone in queue, this action is irreversible.': 'Remove everyone in queue, this action is irreversible.',
      '2.Confirm remove.': 'Confirm remove.',
      '2.Remove': 'Remove',
      '2.Next in the queue': 'Next in the queue',
      '2.Time': 'Time',
      '2.Customer offline for': 'Customer offline for',
      '2.Walk up customer name:': 'Walk up customer name:',
      '2.Customer bid:': 'Customer bid:',
      '2.Wait time + bid (min)': 'Wait time + bid (min)',
      '2.Status': 'Status',
      "2.Enter the customer's PIN:": "Enter the customer's PIN:",
      '2.Next': 'Next',
      '2.Next in the queue is a group of': 'Next in the queue is a group of',
      '2.Congratulations, you are next in the queue': 'Congratulations, you are next in the queue',
      '2.Your PIN': 'Your PIN',
      '2.Show your PIN to the queue administrator.': 'Show your PIN to the queue administrator.',
      '2.If you are no longer available:': 'If you are no longer available:',
      '2.Go to homepage': 'Go to homepage',
      '2.Open in a new window': 'Open in a new window',
      '2.Go': 'Go',
      '3.MainPage': 'MainPage',
      '3.Join': 'Join',
      '3.Leave': 'Leave',
      '3.Admin Mode': 'Admin Mode',
      '3.unlimited': 'unlimited',
      '3.Wait time + bid (min)': 'Wait time + bid (min)',
      '3.Status': 'Status',
      '3.Currently not in queue, click ': 'Currently not in queue, click ',
      '3.above.': 'above.',
      '3.Purge': 'Purge',
      '3.Add': 'Add',
      '3.Bid': 'Bid',
      '4.Settings': 'Settings',
      '4.Change password': 'Change password',
      '4.Old Password:':'Old Password:',
      '4.New Password:': 'New Password:',
      '4.Confirm password:': 'Confirm password:',
      '4.Submit': 'Submit',
      '4.New password does not match': 'New password does not match',
      '4.Create a new queue': 'Create a new queue',
      '4.Name of the business:': 'Name of the business:',
      '4.Address line 1:': 'Address line 1:',
      '4.Address line 2:': 'Address line 2:',
      '4.Country:': 'Country:',
      '4.United States': 'United States',
      '4.Phone number:': 'Phone number:',
      '4.Promotion code:': 'Promotion code:',
      '4.Referrer email*:': 'Referrer email*:',
      '4.*Note: referrer receives': '*Note: referrer receives',
      "4.of customers' bid value for the first year at no additional cost to you. You always receive": "of customers' bid value for the first year at no additional cost to you. You always receive",
      '4.of the bid value.': 'of the bid value.',
      '4.I have read and agree to the': 'I have read and agree to the',
      '4.terms of service': 'terms of service',
      '4.Select subscription type:': 'Select subscription type:',
      '4.Delete account': 'Delete account',
      "4.Note: if it's been a while since logged in, you might have to log out first before deleting the account.": "Note: if it's been a while since logged in, you might have to log out first before deleting the account.",
      '4.I understand that deleting this account is a permanent action, and all associated data including tokens and subscriptions will be deleted as well.': 'I understand that deleting this account is a permanent action, and all associated data including tokens and subscriptions will be deleted as well.',
      '4.Join the queue for free': 'Join the queue for free',
      '4.bid to get ahead': 'bid to get ahead',
      "4.Duplicate emails not allowed": "Duplicate emails not allowed",
      "4.Invalid email, must be of regex form:": "Invalid email, must be of regex form:",
      "4.Dollars per minute must be a positive number": "Dollars per minute must be a positive number",
      "4.Max must be empty or non-zero": "Max must be empty or non-zero",
      "4.Email cannot be empty": "Email cannot be empty",
      '4.Edit queue:': 'Edit queue:',
      '4.Revenue:': 'Revenue:',
      '4.Revenue this month:': 'Revenue this month:',
      '4.Revenue last month:': 'Revenue last month:',
      '4.Premium data:': 'Premium data:',
      '4.Queue stat from 1 day ago:': 'Queue stat from 1 day ago:',
      '4.Queue stat from 2 days ago:': 'Queue stat from 2 days ago:',
      '4.Download last month records:': 'Download last month records:',
      '4.Download': 'Download',
      '4.Queue parameters:': 'Queue parameters:',
      '4.Warning: Changing queue parameters will impact customers currently in queue.': 'Warning: Changing queue parameters will impact customers currently in queue.',
      '4.Dollars per minute (price of each bid):': 'Dollars per minute (price of each bid):',
      '4.Maximum bid (in minutes, empty = unlimited):': 'Maximum bid (in minutes, empty = unlimited):',
      '4.Queue administrators:': 'Queue administrators:',
      '4.Give permission for those who can manage the queue, enter their email address used to signup for this site.': 'Give permission for those who can manage the queue, enter their email address used to signup for this site.',
      '4.Name': 'Name',
      '4.Confirm changes.': 'Confirm changes.',
      '4.Change saved': 'Change saved',
      '4.Queue Info:': 'Queue Info:',
      '4.Go to queue': 'Go to queue',
      '4.Edit queue': 'Edit queue',
      '4.Queue created:': 'Queue created:',
      '4.Queue expires:': 'Queue expires:',
      '4.Premium account:': 'Premium account:',
      '4.Referrer:': 'Referrer:',
      '4.Welcome': 'Welcome',
      '4.Logout': 'Logout',
      '4.Account': 'Account',
      '4.Transactions': 'Transactions',
      '4.Time': 'Time',
      '4.Amount': 'Amount',
      '4.Manage invoice and subscription through Stripe:': 'Manage invoice and subscription through Stripe:',
      '4.Stripe Customer': 'Stripe Customer',
      '4.Business owner': 'Business owner',
      '4.Stripe Account': 'Stripe Account',
      '4.Queue Name': 'Queue Name',
      '4.Details': 'Details',
      '4.Edit': 'Edit',
      '4.Create New Queue': 'Create New Queue',
      '4.To add a new queue, first create a Stripe connected account:': 'To add a new queue, first create a Stripe connected account:',
      '4.Create Stripe Account': 'Create Stripe Account',
      '4.More info needed with registration:': 'More info needed with registration:',
      '5.unauth': 'unauth',
      '5.Create an account': 'Create an account',
      '5.Email:': 'Email:',
      '5.Password:': 'Password:',
      '5.Confirm password:': 'Confirm password:',
      '5.Submit': 'Submit',
      '5.Password does not match': 'Password does not match',
      '5.Login': 'Login',
      "5.Don't have an account?": "Don't have an account?",
      '5.Click here': 'Click here',
      '5.to create.': 'to create.',
      '5.Forgot password?': 'Forgot password?',
      '5.to reset.': 'to reset.',
      '5.Login with ': 'Login with ',
      '5.By continuing, you are indicating that you accept our': 'By continuing, you are indicating that you accept our',
      '5.privacy policy': 'privacy policy',
      '5.and': 'and',
      '5.terms of service': 'terms of service',
      '5.Reset Password':'Reset Password',
      '5.Reset link sent, check your inbox or spam folder.': 'Reset link sent, check your inbox or spam folder.',
      '5.You must verify your email first, click below to resend verification.': 'You must verify your email first, click below to resend verification.',
      '5.Verification sent, check your inbox.': 'Verification sent, check your inbox.',
      
    },
    
    // <FormattedMessage id='0' />

    'es': {
      '0': 'HomePage',
      '0.Welcome to Qbid': 'Bienvenido a Qbid',
      '0.Get into a queue by clicking this button:': 'Entra en una cola haciendo clic en este botón:',
      '0.Queue': 'Cola',
      '0.Business owners:': "Propietarios de negocios:",
      '0.Setup your queue from the settings menu: ⚙': "Configura tu cola desde el menú de ajustes: ⚙",
      '1': 'Layout',
      '1.Login':'Iniciar sesión ',
      '1.Back': 'Volver',
      '1.Error': 'Error',
      '1.Success': 'Éxito',
      '2.Modals': 'Modals',
      '2.Join the queue': 'Únase a la cola',
      '2.Number of people in your group:': 'Número de personas en su grupo:',
      '2.*By joining the queue, I agree to the': '*Al unirme a la cola, acepto las',
      '2.terms of service': 'condiciones del servicio',
      '2.and': 'y',
      '2.privacy policy.': 'política de privacidad.',
      '2.In addition I understand I might be removed by the queue administrator for not present when called or for any other reason.': 'Además, entiendo que puedo ser eliminado por el administrador de la cola por no estar presente cuando se me llama o por cualquier otra razón.',
      '2.Join as admin': 'Únase como admin',
      '2.Join': 'Únase',
      '2.Leave queue': 'Dejar la cola',
      '2.Are you sure you would like to leave the queue? Your place will be lost.': '¿Seguro que quiere abandonar la cola? Su plaza se perderá.',
      '2.Are you sure you would like to leave as the admin?': '¿Estás seguro de que quieres dejar de ser el administrador?',
      '2.Confirm leave.': 'Confirme la salida.',
      '2.Leave': 'Salida',
      '2.Change group size': 'Cambiar el tamaño del grupo',
      '2.Submit': 'Enviar',
      '2.Bid for your place in queue': 'Oferta por su lugar en la cola',
      '2.Rate:': 'Tasa:',
      '2.Max bid:': 'Oferta máxima:',
      '2.unlimited': 'ilimitado',
      '2.Bid in minutes:': 'Oferta en minutos:',
      '2.For:': 'Para:',
      '2.Insufficient fund': 'Fondo insuficiente',
      '2.Your place:': 'Su lugar:',
      '2.Before:': 'Antes:',
      '2.After:': 'Después:',
      '2.*Your bid will only be deducted when you are called to the front of the queue, you can modify your bid at any time.': '*Su oferta sólo se descontará cuando se le llame al frente de la cola, puede modificar su oferta en cualquier momento.',
      '2.Recharge': 'Recarga',
      '2.Bid': 'Oferta',
      '2.Recharge account': 'Cuenta de recarga',
      '2.Close':'Cerrar',
      '2.Get next in queue': 'Obtener el siguiente en la cola',
      '2.Group size': 'Tamaño del grupo',
      '2.From:': 'De:',
      '2.To:':'A:',
      '2.Get': 'Obtenga',
      '2.Add walk in customer':'Añadir cliente sin cita previa',
      '2.Name of customer:':  'Nombre del cliente:',
      '2.Number of people in group:': 'Número de personas en el grupo:',
      '2.Note: The customer cannot be modified once added.': 'Nota: El cliente no puede ser modificado una vez añadido.',
      '2.Name already in queue, please pick another': 'El nombre ya está en la cola, por favor elija otro',
      '2.Please enter a name': 'Por favor, introduzca un nombre',
      '2.Add': 'añadir',
      '2.Purge the queue': 'Purgar la cola',
      '2.Remove everyone in queue, this action is irreversible.': 'Retirar a todos los de la cola, esta acción es irreversible.',
      '2.Confirm remove.': 'Confirmar la eliminación.',
      '2.Remove': 'Eliminar',
      '2.Next in the queue': 'Siguiente en la cola',
      '2.Time': 'Tiempo',
      '2.Customer offline for': 'Cliente fuera de línea para',
      '2.Walk up customer name:': 'Nombre del cliente sin cita previa:',
      '2.Customer bid:': 'Oferta del cliente:',
      '2.Wait time + bid (min)': 'Tiempo de espera + oferta (min)',
      '2.Status': 'Estatus',
      "2.Enter the customer's PIN:": "Introducir el PIN del cliente:",
      '2.Next': 'Siguiente',
      '2.Congratulations, you are next in the queue': 'Enhorabuena, eres el siguiente en la cola',
      '2.Your PIN': 'Su PIN',
      '2.Show your PIN to the queue administrator.': 'Muestre su PIN al administrador de la cola.',
      '2.If you are no longer available:': 'Si ya no está disponible:',
      '2.Go to homepage': 'Ir a la página de inicio',
      '2.Open in a new window': 'Abrir en una nueva ventana',
      '2.Go': 'Ir',
      '3.MainPage': 'MainPage',
      '3.Join': 'Únase',
      '3.Leave': 'Váyase',
      '3.Admin Mode': 'Modo de Admin',
      '3.unlimited': 'ilimitado',
      '3.Wait time + bid (min)': 'Tiempo de espera + oferta (min)',
      '3.Status': 'Estatus',
      '3.Currently not in queue, click ': 'Actualmente no está en la cola, haga clic ',
      '3.above.': 'arriba.',
      '3.Purge': 'Purga',
      '3.Add': 'Añadir',
      '3.Bid': 'Oferta',
      '4.Settings': 'Ajustes',
      '4.Change password': 'Cambiar contraseña',
      '4.Old Password:':'Contraseña antigua:',
      '4.New Password:': 'Nueva contraseña:',
      '4.Confirm password:': 'Confirmar contraseña:',
      '4.Submit': 'Enviar',
      '4.New password does not match': 'La nueva contraseña no coincide',
      '4.Create a new queue': 'Crear una nueva cola',
      '4.Name of the business:': 'Nombre de la empresa:',
      '4.Address line 1:': 'Línea de dirección 1:',
      '4.Address line 2:': 'Línea de dirección 2:',
      '4.Country:': 'País:',
      '4.United States': 'Estados Unidos',
      '4.Phone number:': 'Número de teléfono:',
      '4.Promotion code:': 'Código de promoción:',
      '4.Referrer email*:': 'Correo electrónico del remitente*:',
      '4.*Note: referrer receives': '*Nota: el remitente recibe',
      "4.of customers' bid value for the first year at no additional cost to you. You always receive": "del valor de la oferta de los clientes durante el primer año sin coste adicional para usted. Usted siempre recibe",
      '4.of the bid value.': 'del valor de la oferta.',
      '4.I have read and agree to the': 'He leído y acepto la',
      '4.terms of service': 'condiciones de servicio',
      '4.Select subscription type:': 'Seleccione el tipo de suscripción:',
      '4.Delete account': 'Eliminar la cuenta',
      "4.Note: if it's been a while since logged in, you might have to log out first before deleting the account.": "Nota: si hace tiempo que no te conectas, es posible que tengas que cerrar la sesión antes de eliminar la cuenta.",
      '4.I understand that deleting this account is a permanent action, and all associated data including tokens and subscriptions will be deleted as well.': 'Entiendo que la eliminación de esta cuenta es una acción permanente, y que todos los datos asociados, incluidos los tokens y las suscripciones, se eliminarán también.',
      '4.Join the queue for free': 'Únase a la cola de forma gratuita',
      '4.bid to get ahead': 'oferta para salir adelante',
      "4.Duplicate emails not allowed": "No se permite la duplicación de correos electrónicos",
      "4.Invalid email, must be of regex form:": "Correo electrónico inválido, debe ser de forma regex:",
      "4.Dollars per minute must be a positive number": "Los dólares por minuto deben ser un número positivo",
      "4.Max must be empty or non-zero": "Max debe estar vacío o ser distinto de cero",
      "4.Email cannot be empty": "El correo electrónico no puede estar vacío",
      '4.Edit queue:': 'Editar cola:',
      '4.Revenue:': 'Ingresos:',
      '4.Revenue this month:': 'Ingresos este mes:',
      '4.Revenue last month:': 'Ingresos del mes pasado:',
      '4.Premium data:': 'Datos de la prima:',
      '4.Queue stat from 1 day ago:': 'Cola de espera de hace 1 día:',
      '4.Queue stat from 2 days ago:': 'Cola de espera de hace 2 días:',
      '4.Download last month records:': 'Descarga de registros del último mes:',
      '4.Download': 'Descarga',
      '4.Queue parameters:': 'Parámetros de la cola:',
      '4.Warning: Changing queue parameters will impact customers currently in queue.': 'Advertencia: El cambio de los parámetros de la cola afectará a los clientes que están actualmente en la cola.',
      '4.Dollars per minute (price of each bid):': 'Dólares por minuto (precio de cada oferta):',
      '4.Maximum bid (in minutes, empty = unlimited):': 'Oferta máxima (en minutos, vacío = ilimitado):',
      '4.Queue administrators:': 'Administradores de colas:',
      '4.Give permission for those who can manage the queue, enter their email address used to signup for this site.': 'Dar permiso a los que pueden gestionar la cola, introduzca su dirección de correo electrónico utilizado para registrarse en este sitio.',
      '4.Name': 'Nombre',
      '4.Confirm changes.': 'Confirme los cambios.',
      '4.Change saved': 'Cambio guardado',
      '4.Queue Info:': 'Información de la cola:',
      '4.Go to queue': 'Ir a la cola',
      '4.Edit queue': 'Editar cola',
      '4.Queue created:': 'Cola creada:',
      '4.Queue expires:': 'La cola expira:',
      '4.Premium account:': 'Cuenta Premium:',
      '4.Referrer:': 'Referente:',
      '4.Welcome': 'Bienvenido',
      '4.Logout': 'Cierre de sesión',
      '4.Account': 'Cuenta',
      '4.Transactions': 'Transacciones',
      '4.Time': 'Tiempo',
      '4.Amount': 'Importe',
      '4.Manage invoice and subscription through Stripe:': 'Gestionar la factura y la suscripción a través de Stripe:',
      '4.Stripe Customer': 'Cliente de Stripe',
      '4.Business owner': 'Empresario',
      '4.Stripe Account': 'Cuenta Stripe',
      '4.Queue Name': 'Nombre de la cola',
      '4.Details': 'Detalles',
      '4.Edit': 'Editar',
      '4.Create New Queue': 'Crear una nueva cola',
      '4.To add a new queue, first create a Stripe connected account:': 'Para añadir una nueva cola, primero hay que crear una cuenta conectada a Stripe:',
      '4.Create Stripe Account': 'Crear cuenta de Stripe',
      '4.More info needed with registration:': 'Se necesita más información con la inscripción:',
      '5.unauth': 'unauth',
      '5.Create an account': 'Create an account',
      '5.Email:': 'Correo electrónico:',
      '5.Password:': 'Contraseña:',
      '5.Confirm password:': 'Confirmar contraseña:',
      '5.Submit': 'Enviar',
      '5.Password does not match': 'La contraseña no coincide',
      '5.Login': 'Iniciar sesión',
      "5.Don't have an account?": "¿No tiene una cuenta?",
      '5.Click here': 'Haga clic aquí',
      '5.to create.': 'para crear.',
      '5.Forgot password?': '¿Ha olvidado su contraseña?',
      '5.to reset.': 'para restablecer.',
      '5.Login with ': 'Iniciar sesión con ',
      '5.By continuing, you are indicating that you accept our': 'Al continuar, está indicando que acepta nuestra',
      '5.privacy policy': 'política de privacidad',
      '5.and': 'y',
      '5.terms of service': 'condiciones de servicio',
      '5.Reset Password': 'Restablecer contraseña',
      '5.Reset link sent, check your inbox or spam folder.': 'Se ha enviado el enlace de restablecimiento, compruebe su bandeja de entrada o su carpeta de correo no deseado.',
      '5.You must verify your email first, click below to resend verification.': 'Debe verificar su correo electrónico primero, haga clic abajo para reenviar la verificación.',
      '5.Verification sent, check your inbox.': 'Verificación enviada, revisa tu bandeja de entrada.',
      

    },
    'fr': {
      '0': 'HomePage',
      '0.Welcome to Qbid': 'Bienvenue à Qbid',
      '0.Get into a queue by clicking this button:': "Entrez dans une queue en cliquant sur ce bouton :",
      '0.Queue': "Queue",
      '0.Business owners:': "Propriétaires de l'entreprise :",
      '0.Setup your queue from the settings menu: ⚙': "Configurez votre file d'attente à partir du menu des paramètres : ⚙",
      '1': 'Layout',
      '1.Login':'Connexion',
      '1.Back': 'Dos',
      '1.Error': 'Erreur',
      '1.Success': 'Succès',
      '2.Modals': 'Modals',
      '2.Join the queue': 'Rejoindre la queue',
      '2.Number of people in your group:': 'Nombre de personnes dans votre groupe :',
      '2.*By joining the queue, I agree to the': "*En rejoignant la file d'attente, j'accepte les",
      '2.terms of service': 'conditions de service',
      '2.and': 'et',
      '2.privacy policy.': 'politique de confidentialité.',
      '2.In addition I understand I might be removed by the queue administrator for not present when called or for any other reason.': "En outre, je comprends que je peux être retiré par l'administrateur de la file d'attente pour ne pas être présent à l'appel ou pour toute autre raison.",
      '2.Join as admin': "Rejoignez-nous en tant qu'admin",
      '2.Join': 'Rejoignez',
      '2.Leave queue': 'Quitter la queue',
      '2.Are you sure you would like to leave the queue? Your place will be lost.': "Vous êtes sûr de vouloir quitter la file d'attente ? Votre place sera perdue.",
      '2.Are you sure you would like to leave as the admin?': "Êtes-vous sûr de vouloir quitter le poste d'administrateur ?",
      '2.Confirm leave.': 'confirmer le quitter.',
      '2.Leave': 'Quitter',
      '2.Change group size': 'Modifier la taille du groupe',
      '2.Submit': 'Soumettre',
      '2.Bid for your place in queue': 'Faites une offre pour votre place dans la queue',
      '2.Rate:': 'Taux :',
      '2.Max bid:': 'Enchère maximale :',
      '2.unlimited': 'illimité',
      '2.Bid in minutes:': 'Enchérissez en quelques minutes :',
      '2.For:': 'Pour :',
      '2.Insufficient fund': 'Fonds insuffisant',
      '2.Your place:': 'Votre place :',
      '2.Before:': 'Avant :',
      '2.After:': 'Après :',
      '2.*Your bid will only be deducted when you are called to the front of the queue, you can modify your bid at any time.': '*Votre offre ne sera déduite que lorsque vous serez appelé en tête de queue, vous pouvez modifier votre offre à tout moment.',
      '2.Recharge': 'Recharge',
      '2.Bid': 'Offre',
      '2.Recharge account': 'Compte de recharge',
      '2.Close':'Fermer',
      '2.Get next in queue': 'Obtenir le prochain dans la queue',
      '2.Group size': 'Taille du groupe',
      '2.From:': 'De :',
      '2.To:':'A :',
      '2.Get': 'Obtenez',
      '2.Add walk in customer':'Ajouter un client à domicile',
      '2.Name of customer:':  'Nom du client :',
      '2.Number of people in group:': 'Nombre de personnes dans le groupe :',
      '2.Note: The customer cannot be modified once added.': 'Note : Le client ne peut pas être modifié une fois ajouté.',
      '2.Name already in queue, please pick another': 'Nom déjà dans la queue, veuillez en choisir un autre',
      '2.Please enter a name': 'Veuillez entrer un nom',
      '2.Add': 'Ajouter',
      '2.Purge the queue': 'Purger la queue',
      '2.Remove everyone in queue, this action is irreversible.': 'Retirez toutes les personnes dans la queue, cette action est irréversible.',
      '2.Confirm remove.': 'Confirmez la suppression.',
      '2.Remove': 'Supprimer',
      '2.Next in the queue': 'Suivant dans la queue',
      '2.Time': 'Temps',
      '2.Customer offline for': 'Client hors ligne pour',
      '2.Walk up customer name:': 'Marche vers le nom du client :',
      '2.Customer bid:': 'Offre des clients :',
      '2.Wait time + bid (min)': "Temps d'attente + offre (min)",
      '2.Status': 'Statut',
      "2.Enter the customer's PIN:": "Saisissez le code PIN du client :",
      '2.Next': 'Suivant',
      '2.Next in the queue is a group of': 'Le prochain en lice est un groupe de',
      '2.Congratulations, you are next in the queue': "Félicitations, vous êtes le prochain dans la file d'attente",
      '2.Your PIN': 'Votre PIN',
      '2.Show your PIN to the queue administrator.': "Montrez votre code PIN à l'administrateur de la file d'attente.",
      '2.If you are no longer available:': "Si vous n'êtes plus disponible :",
      '2.Go to homepage': "Aller à la page d'accueil",
      '2.Open in a new window': 'Ouvrir dans une nouvelle fenêtre',
      '2.Go': 'Aller',
      '3.MainPage': 'MainPage',
      '3.Join': 'Rejoignez',
      '3.Leave': 'Laissez',
      '3.Admin Mode': 'Mode Admin',
      '3.unlimited': 'illimité',
      '3.Wait time + bid (min)': "Temps d'attente + offre (min)",
      '3.Status': 'Statut',
      '3.Currently not in queue, click ': "Actuellement pas dans la file d'attente, cliquez ",
      '3.above.': 'ci-dessus.',
      '3.Purge': 'Purge',
      '3.Add': 'Ajouter',
      '3.Bid': 'Offre',
      '4.Settings': 'Paramètres',
      '4.Change password': 'Modifier le mot de passe',
      '4.Old Password:':'Ancien mot de passe :',
      '4.New Password:': 'Nouveau mot de passe :',
      '4.Confirm password:': 'Confirmez le mot de passe :',
      '4.Submit': 'Soumettre',
      '4.New password does not match': 'Le nouveau mot de passe ne correspond pas',
      '4.Create a new queue': 'Créer une nouvelle queue',
      '4.Name of the business:': "Nom de l'entreprise :",
      '4.Address line 1:': "Ligne d'adresse 1 :",
      '4.Address line 2:': "Ligne d'adresse 2 :",
      '4.Country:': 'Pays :',
      '4.United States': 'États-Unis',
      '4.Phone number:': 'Numéro de téléphone :',
      '4.Promotion code:': 'Code de promotion :',
      '4.Referrer email*:': 'Courriel du référent* :',
      '4.*Note: referrer receives': '*Note : le référent reçoit',
      "4.of customers' bid value for the first year at no additional cost to you. You always receive": "de la valeur des offres des clients pendant la première année, sans frais supplémentaires pour vous. Vous recevez toujours",
      '4.of the bid value.': "de la valeur de l'offre.",
      '4.I have read and agree to the': "J'ai lu et accepté les",
      '4.terms of service': 'conditions de service',
      '4.Select subscription type:': "Sélectionnez le type d'abonnement :",
      '4.Delete account': 'Supprimer le compte',
      "4.Note: if it's been a while since logged in, you might have to log out first before deleting the account.": "Remarque : si vous n'avez pas été connecté depuis longtemps, vous devrez peut-être vous déconnecter avant de supprimer le compte.",
      '4.I understand that deleting this account is a permanent action, and all associated data including tokens and subscriptions will be deleted as well.': 'Je comprends que la suppression de ce compte est une action permanente, et que toutes les données associées, y compris les jetons et les abonnements, seront également supprimées.',
      '4.Join the queue for free': 'Rejoignez la queue gratuitement',
      '4.bid to get ahead': "pour prendre de l'avance",
      "4.Duplicate emails not allowed": "Les doublons d'e-mails ne sont pas autorisés",
      "4.Invalid email, must be of regex form:": "Courriel invalide, doit être de type regex :",
      "4.Dollars per minute must be a positive number": "Le dollar par minute doit être un nombre positif",
      "4.Max must be empty or non-zero": "Max doit être vide ou non nul",
      "4.Email cannot be empty": "L'e-mail ne peut pas être vide",
      '4.Edit queue:': "Modifier la file d'attente :",
      '4.Queue parameters:': "Paramètres de la file d'attente :",
      '4.Warning: Changing queue parameters will impact customers currently in queue.': "Avertissement : La modification des paramètres de la file d'attente aura un impact sur les clients actuellement en attente.",
      '4.Dollars per minute (price of each bid):': 'Dollars par minute (prix de chaque offre) :',
      '4.Maximum bid (in minutes, empty = unlimited):': 'Offre maximale (en minutes, vide = illimité) :',
      '4.Queue administrators:': 'Administrateurs de la queue :',
      '4.Give permission for those who can manage the queue, enter their email address used to signup for this site.': "Donnez la permission à ceux qui peuvent gérer la file d'attente, entrez leur adresse e-mail utilisée pour s'inscrire sur ce site.",
      '4.Name': 'Nom',
      '4.Confirm changes.': 'Confirmez les changements.',
      '4.Change saved': 'Changement enregistré',
      '4.Queue Info:': "Informations sur la file d'attente :",
      '4.Go to queue': 'Aller à la queue',
      '4.Edit queue': 'Modifier la queue',
      '4.Revenue:': 'Recettes :',
      '4.Revenue this month:': 'Revenu ce mois-ci :',
      '4.Revenue last month:': 'Revenu du mois dernier :',
      '4.Premium data:': 'Données sur les primes :',
      '4.Queue stat from 1 day ago:': "État de la queue d'il y a 1 jour :",
      '4.Queue stat from 2 days ago:': "État de la queue d'il y a 2 jours :",
      '4.Download last month records:': 'Télécharger les enregistrements du mois dernier :',
      '4.Download': 'Télécharger',
      '4.Queue created:': 'Queue créée :',
      '4.Queue expires:': 'La queue expire :',
      '4.Premium account:': 'Compte Premium :',
      '4.Referrer:': 'Référent :',
      '4.Welcome': 'Bienvenue',
      '4.Logout': 'Logout',
      '4.Account': 'Compte',
      '4.Transactions': 'Transactions',
      '4.Time': 'Heure',
      '4.Amount': 'Montant',
      '4.Manage invoice and subscription through Stripe:': 'Gérez les factures et les abonnements via Stripe :',
      '4.Stripe Customer': 'Client de Stripe',
      '4.Business owner': "Propriétaire d'entreprise",
      '4.Stripe Account': 'Compte Stripe',
      '4.Queue Name': 'Nom de la queue',
      '4.Details': 'Détails',
      '4.Edit': 'Modifier',
      '4.Create New Queue': 'Créer une nouvelle queue',
      '4.To add a new queue, first create a Stripe connected account:': "Pour ajouter une nouvelle file d'attente, créez d'abord un compte Stripe connecté :",
      '4.Create Stripe Account': 'Créer un compte Stripe',
      '4.More info needed with registration:': "Plus d'informations nécessaires lors de l'inscription :",
      '5.unauth': 'unauth',
      '5.Create an account': 'Créer un compte',
      '5.Email:': 'Courriel :',
      '5.Password:': 'Mot de passe :',
      '5.Confirm password:': 'Confirmez le mot de passe :',
      '5.Submit': 'Soumettre',
      '5.Password does not match': 'Le mot de passe ne correspond pas',
      '5.Login': 'Connexion',
      "5.Don't have an account?": "Vous n'avez pas de compte ?",
      '5.Click here': 'Cliquez ici',
      '5.to create.': 'pour créer.',
      '5.Forgot password?': 'Mot de passe oublié ?',
      '5.to reset.': 'pour réinitialiser.',
      '5.Login with ': 'Connectez-vous avec ',
      '5.By continuing, you are indicating that you accept our': 'En continuant, vous indiquez que vous acceptez notre',
      '5.privacy policy': 'politique de confidentialité',
      '5.and': 'et',
      '5.terms of service': 'conditions de service',
      '5.Reset Password':'Réinitialiser le mot de passe',
      '5.Reset link sent, check your inbox or spam folder.': 'Le lien de réinitialisation a été envoyé, vérifiez votre boîte de réception ou votre dossier spam.',
      '5.You must verify your email first, click below to resend verification.': "Vous devez d'abord vérifier votre email, cliquez ci-dessous pour renvoyer la vérification.",
      '5.Verification sent, check your inbox.': 'Vérification envoyée, vérifiez votre boîte de réception.',
      
    }

  };
  