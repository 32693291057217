
import { useState, useEffect, useRef } from "react";

import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';

import {
    useOutletContext,
    useParams,
} from "react-router-dom";

import { useAuth } from '../AuthContent';
import { getRealtime } from '../GetRealtime';

import { ref, onValue, update, getDatabase, serverTimestamp } from "firebase/database";
import { getApp  } from "firebase/app";
import { doc, updateDoc } from "firebase/firestore";
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL, deleteObject  } from "firebase/storage";
import { FormattedMessage } from "react-intl";


export default function QueueDetails() {

    const { activeBusinessId, loading, setLoading, addToast } = useOutletContext();
    const { uid, appFirestore } = useAuth();

    let { businessId="" } = useParams();
  
    const dcRealtime = useRef();

    const re = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

    
    const [ imgUrl, setImgUrl ] = useState();
    const [ imgRef, setImgRef ] = useState();

    useEffect(() => {
        const storage = getStorage();
        const imgRef2 = storageRef(storage, 'img/'+uid+'/'+businessId);
        setImgRef(imgRef2);
        getDownloadURL(imgRef2).then((url) => {
            setImgUrl(url);
        }).catch(
            (errors) => {
                if (errors.code !== 'storage/object-not-found')
                    console.log(errors);
        })}
    ,[uid,businessId]);

    useEffect(() => {
        if (activeBusinessId === businessId) {
            const databaseURL = getRealtime(businessId);
            const realtimeRef = getDatabase(getApp(databaseURL));
            const unsub1 = onValue(ref(realtimeRef, `b/${businessId}/p`), (snapshot) => {
                setInput((prev) => ({...prev, ...snapshot.val().i }) );
            });
            const unsub2 = onValue(ref(realtimeRef, `b/${businessId}/o`), (snapshot) => {
                const admins = snapshot.val()?.employees ? Object.entries(snapshot.val().employees) : [];
                admins.push(["",""]);
                setInput((prev) => ({...prev, admins: admins.map( (x) => ([x[0].replaceAll('%2E','.'),x[1]]) ) }) );
            });
            dcRealtime.current = () => {unsub1(); unsub2();};
            
            
        }
    },[ activeBusinessId, businessId ]);

    useEffect(() => {
        return () => {
          dcRealtime.current && dcRealtime.current();
        };
    }, []);

    const [ errors, setErrors ] = useState({});
    const [ focus, setFocus ] = useState(0);
    const [ changeSaved, setChangeSaved ] = useState(false);

    const [input, setInput] = useState({
        name: '',
        add1: '',
        add2: '',
        dpm: '',
        max:'',
        phone: '',
        admins: [ ["",""] ],
    });

    function onInputChange (e) {
        const { name, value } = e.target;
        setChangeSaved(false);
        if (name.startsWith('admins') ){
            setInput( (prev) => {
                const newArray = prev.admins;
                if (name === 'adminsEmail') {
                    newArray[ newArray.length - 1 ][0] = value;
                    setFocus(1);
                } else if (name ==='adminsName') {
                    newArray[ newArray.length - 1 ][1] = value;
                    setFocus(2);
                };
                return ({ ...prev, admins: newArray});
                });
        } else {
            setInput( (prev) => ({
                ...prev,
                [name]: value
            }));
        }
        
    }

    function validate () {
        if (input.admins[input.admins.length - 1][0] !== ''){
            if ( input.admins.slice(0,-1).map((x) => (x[0])).includes(input.admins[ input.admins.length - 1 ][0]) ){
                setErrors((prev) => ({...prev, admins:<FormattedMessage id="4.Duplicate emails not allowed" />}));
            } else if ( !re.test(input.admins[input.admins.length - 1][0]) ) {
                setErrors((prev) => ({...prev, admins:<FormattedMessage id="4.Invalid email, must be of regex form:" /> +"/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\\.[A-Z]{2,}$/i"}));
            };
        }

        if ( !( Number(input.dpm) > 0) ){
            setErrors((prev) => ({...prev, dpm:<FormattedMessage id="4.Dollars per minute must be a positive number" />}));
        }

        if ( !( Number(input.max) >= 0) && (input.max !=='') ){
            setErrors((prev) => ({...prev, max:<FormattedMessage id="4.Max must be empty or non-zero" />}));
        }
    }

    function addRow (event) {
        event.preventDefault();
        validate();
        if ( !errors.admins ) {
            if (input.admins[input.admins.length - 1][0] === ''){
                setErrors((prev) => ({...prev, admins:<FormattedMessage id="4.Email cannot be empty" />}));
            } else{
                setInput( (prev) => {
                    const newArray = prev.admins;
                    newArray.push(["",""])
                    return ({ ...prev, admins: newArray});
                });
            } 
        }
    };

    function deleteRow (event) {
        event.preventDefault();
        setInput( (prev) => {
            const newArray = prev.admins;
            return ({ ...prev, admins: newArray.filter( (_,i) => (i !== Number(event.target.parentElement.parentElement.id))) });
        })
    }

    function handleFileSubmit (event){
        event.preventDefault();
        // 'file' comes from the Blob or File API
        if ( event.target?.[1]?.files?.[0] ){
            setLoading(true);
            const upload_file = event.target[1].files[0];

            uploadBytes(imgRef, upload_file).then((_snapshot) => {
                getDownloadURL(imgRef).then((url) => {
                    setImgUrl(url);
                }).catch(
                    (errors) => {
                        console.log(errors);
                    }
                )
                setLoading(false);
            }).catch(
            (e) => {
                console.log(e);
                setLoading(false);
            });
        }
    }

    function handleSubmit( event) {
        event.preventDefault();
        validate();
        if (Object.keys(errors).length === 0 && activeBusinessId === businessId){
            const adminsObj = (input.admins.length === 1 && input.admins[0][0] === '') ? {} : input.admins.reduce(function(result, item, _index, _array) {
                if (item[0] !==''){
                    result[item[0].replaceAll('.','%2E')] = item[1]; 
                }
                return result;
              }, {});
            const toUpdate = {
                name: input.name,
                add1: input.add1,
                add2: input.add2,
                dpm:  Number(input.dpm),
                max:  input.max === '' ? '' : Number(input.max),
                phone: input.phone,
                updated: serverTimestamp()
            };
            const databaseURL = getRealtime(businessId);
            const realtimeRef = getDatabase(getApp(databaseURL));
            setLoading(true);

            try {
            const promise1 = update( ref(realtimeRef, `b/${businessId}/p/i`), toUpdate );
            const promise2 = update( ref(realtimeRef, `b/${businessId}/o`), { employees : adminsObj } );

            const docRef = doc( appFirestore, "u", uid);
            const promise3 =  updateDoc(docRef, {
                s:{ [businessId]: input.name }
              });
            Promise.all([promise1, promise2, promise3]).then(() => {
                setLoading(false);
                setChangeSaved(true);
            });
            } catch (error) {
                setLoading(false);
                addToast( {type:"Error", message:error.code} );
            }
        }
    }  

    return (
        <div className="p-3">

            <form onSubmit={handleFileSubmit}>
            <h3>Business image:</h3>
            {!imgUrl && <img className="py-3" alt="business profile" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAQAAAAAYLlVAAAAOUlEQVR42u3OIQEAAAACIP1/2hkWWEBzVgEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAYF3YDicAEE8VTiYAAAAAElFTkSuQmCC"
                            width={64} heigh={64} ></img>}
            {imgUrl && <img className="py-3" alt="business profile" src={imgUrl} width={64} heigh={64} ></img>}
            {imgUrl && <button className="m-3" disabled = {loading} onClick={() => {
                setLoading(true);
                const storage = getStorage();
                const imgRef64 = storageRef(storage, 'img/'+uid+'/'+businessId+'_64x64');
                deleteObject(imgRef64).then(() => {
                    // File deleted successfully
                }).catch((error) => {
                    console.log(error);
                });
                deleteObject(imgRef).then(() => {
                    setImgUrl(null);
                    setLoading(false);
                }).catch((error) => {
                    setLoading(false);
                    console.log(error);
                });
            }} > Delete </button>}
            <input type="file" id='file' name='file' accept="image/*" onChange={ onInputChange } disabled = {loading} /> 
            <button type="submit" disabled = {loading} >Upload</button>
            <p> *Add a business thumbnail, image will be scaled to 64 x 64 pixels. File limit: 1 MB</p>  

            </form>

            <h3 className="py-3"> <FormattedMessage id='4.Edit queue:' /> </h3>

            <form onSubmit={handleSubmit}>

            <p> <label>
            <FormattedMessage id='4.Name of the business:' /> <input name="name" type="text" required value={input.name}
                        onChange={onInputChange} placeholder="Required" autoFocus />
            </label> </p>

            <p><label>
            <FormattedMessage id='4.Address line 1:' /> <input name="add1" type="text" required value={input.add1}
                        onChange={onInputChange}  placeholder="Required" />
            </label></p>

            <p><label>
            <FormattedMessage id='4.Address line 2:' /> <input name="add2" type="text" required value={input.add2}
                        onChange={onInputChange} placeholder="Required" />
            </label> </p>

            <p><label>
            <FormattedMessage id='4.Phone number:' /> <input name="phone" type="text" required value={input.phone}
                        onChange={onInputChange} placeholder="Required" />
            </label></p>

            <h5> <FormattedMessage id='4.Queue parameters:' /> </h5>

            <p className="mypurple"><FormattedMessage id='4.Warning: Changing queue parameters will impact customers currently in queue.' /></p>

            <p><label>
            <FormattedMessage id='4.Dollars per minute (price of each bid):' /> <input name="dpm" type="text"  value={input.dpm}
                        onFocus={() => setErrors({})} onChange={onInputChange} onBlur={validate} placeholder="Required" />
            </label></p>

            { errors?.dpm && <p style={{ color: "red" }}>{errors.dpm}</p>}

            <p><label>
            <FormattedMessage id='4.Maximum bid (in minutes, empty = unlimited):' /> <input name="max" type="text"  value={input.max}
                        onFocus={() => setErrors({})} onChange={onInputChange} onBlur={validate} placeholder="Optional" />
            </label></p>

            { errors?.max && <p style={{ color: "red" }}>{errors.max}</p>}

            <h5><FormattedMessage id='4.Queue administrators:' /></h5>
            <p><FormattedMessage id='4.Give permission for those who can manage the queue, enter their email address used to signup for this site.' /></p>
            <Table style={{tableLayout:'fixed'}} bordered>
                <thead>
                    <tr>
                        <th style={{width:'44%'}}>Email</th>
                        <th style={{width:'44%'}}><FormattedMessage id='4.Name' /></th>
                        <th style={{width:'12%'}}></th>
                    </tr>
                </thead>
                <tbody>
                {input.admins.map( (row, i, arr) => (
                    ((i === arr.length - 1) && (
                        <tr key={i} id={i} >
                            <td style={{overflowX:"auto"}} > <input name="adminsEmail" autoFocus={focus===1} type="text" size={12} value={row[0]}
                                onChange={onInputChange} onFocus={() => setErrors({})} onBlur={validate} /> </td>
                            <td style={{overflowX:"auto"}} > <input name="adminsName" autoFocus={focus===2} type="text" size={12} value={row[1]}
                                    onChange={onInputChange} /> </td>
                            <td> <div onClick={addRow}>➕</div> </td>
                        </tr>)) || 
                    ((i < arr.length ) && (
                        <tr key={i} id={i}>
                            <td style={{overflowX:"auto"}} > {row[0]} </td>
                            <td style={{overflowX:"auto"}} > {row[1]} </td>
                            <td> <div onClick={deleteRow}>✖️</div>  </td>
                        </tr>))
                    )
                )}
                
                </tbody>
                
            </Table>
                

            { errors?.admins && <p style={{ color: "red" }}>{errors.admins}</p>}

            <p><label>
            <input type='checkbox' name="confirm" required/> <FormattedMessage id='4.Confirm changes.' />
            </label> </p>

            <Button className="rounded" variant="primary" type="submit" disabled = {loading} >
                <FormattedMessage id='4.Submit' />
            </Button>
            { changeSaved && <span className="px-3 text-success"> <FormattedMessage id='4.Change saved' /> </span>}
        </form>

        </div>
    )

}