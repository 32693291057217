
import { useState, useEffect } from 'react';

import Pagination from 'react-bootstrap/Pagination';

import { collection, query, orderBy, startAfter, endBefore, limit, getDocs, where } from "firebase/firestore";  

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { useAuth } from '../AuthContent';

import { FormattedMessage } from "react-intl";

export default function Settings () {
    const { uid, appFirestore } = useAuth();


    const [ page, setPage ] = useState(1);
    const [ after, setAfter ] =  useState(null);
    const [ before, setBefore] = useState(null);
    const [ data, setData ] = useState([]);
    const [localLoading, setLocalLoading] = useState(false);

    useEffect(() => {
        let q;
        setLocalLoading(true);
        if( (after === null) && (before === null)) {
            q = query(collection(appFirestore, "t"), where("uid", "==", uid), orderBy("created", "desc"), limit(10));
        } else if ( after ){
            q = query(collection(appFirestore, "t"), where("uid", "==", uid), orderBy("created", "desc"), startAfter(after), limit(10));
        } else {
            q = query(collection(appFirestore, "t"), where("uid", "==", uid), orderBy("created", "desc"), endBefore(before), limit(10));
        }
            
        getDocs(q).then( (snap) => setData(snap.docs)).finally(() => setLocalLoading(false));

    }, [ before, after, uid, appFirestore ])

    return (
        <div className="p-3">
            <h3> <FormattedMessage id='4.Transactions' /> </h3>

            {data.length > 0  && <Row className = "py-2 bg-primary text-light" > 
                            <Col xs={5}> <FormattedMessage id='4.Time' /> </Col>  
                            <Col xs={2} > <FormattedMessage id='4.Amount' /> </Col>
                            <Col xs={5}> <FormattedMessage id='4.Name' /> </Col>
            </Row>}

            {data.map( (x) => 
                { return <Row key={x.id} className = {`py-2`} >
                    <Col xs={5}> {(new Date(x.data().created)).toLocaleString()} </Col>  
                    <Col xs={2}  >
                        {x.data().type} {(x.data().amount/100).toFixed(2)} 
                    </Col>
                    <Col xs={5} style={{whiteSpace: 'nowrap', overflowX:"hidden"}}> {x.data().name} </Col>
                </Row>})
            }

            { data.length === 0 && <p className='py-2'> No more records </p> }


            <Pagination className = "py-2" disabled={localLoading}>
                <Pagination.First disabled = {page===1} onClick={() => {
                    setAfter(null);
                    setBefore(null);
                    setPage(1);
                }}/>
                <Pagination.Prev disabled = {page===1} onClick={() => {
                    setAfter(null);
                    setBefore( data[0].data().created );
                    setPage( (x) => x - 1 );
                }}/>
                { page > 1 &&  <Pagination.Item onClick={() => {
                    setAfter(null);
                    setBefore( data[0].data().created );
                    setPage( (x) => x - 1 );
                }}>{1}</Pagination.Item>}

                { page > 2 && <Pagination.Ellipsis />}

                { page > 2 && <Pagination.Item onClick={() => {
                    setAfter(null);
                    setBefore( data[0] ? data[0].data().created: 0 );
                    setPage( (x) => x - 1 );
                }}> {page - 1 } </Pagination.Item> }
                <Pagination.Item active> {page} </Pagination.Item>

                {data.length === 10 && <Pagination.Item onClick={() => {
                    setAfter( data[data.length-1].data().created );
                    setBefore( null );
                    setPage( (x) => x + 1 );
                }}> {page+1} </Pagination.Item>}

                <Pagination.Next disabled = { data.length < 10 } onClick={() => {
                    setAfter( data[data.length-1].data().created );
                    setBefore( null );
                    setPage( (x) => x + 1 );
                }}/>
            </Pagination>
        </div>
    )
}