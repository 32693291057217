import './App.css';

//import 'bootstrap/dist/css/bootstrap.min.css';
import './bootstrap.pulse.min.css';


import {
  Routes,
  Route,
  useLocation,
  Navigate,
  BrowserRouter,
} from "react-router-dom";

//import { getAnalytics } from "firebase/analytics";

import MainPage from './components/q/MainPage';
import Login from './components/unauth/Login';
import Verify from './components/unauth/Verify';
import Create from './components/unauth/Create';
import Reset from './components/unauth/Reset';
import Settings from './components/settings/Settings';
import Layout from './components/Layout';
import ChangePass from './components/settings/ChangePass';
import DeleteAccount from './components/settings/DeleteAccount';
import CreateQueue from './components/settings/CreateQueue';
import QueueInfo from './components/settings/QueueInfo';
import QRCode from './components/settings/QRCode';
import QueueDetails from './components/settings/QueueDetails';
import HomePage from './components/HomePage';
import Transactions from './components/settings/Transactions';


import { AuthProvider, useAuth } from './components/AuthContent';

import {useState} from 'react';

import { IntlProvider } from "react-intl";
import { messages } from "./components/i18n/messages";

// no trailing slashes in url!
export const maxWidth = "540px";
export const baseUrl = process.env.REACT_APP_BASE_URL;
export const homeUrl = 'https://www.qying.bid';
export const termsUrl = 'https://www.qying.bid/terms';
export const privacyUrl = 'https://www.qying.bid/privacy';

const defaultLocale = localStorage['locale'] ? localStorage['locale'] : ( (navigator.language || navigator.userLanguage) ?? 'en-US'); // English is default locale if none is set


export default function App() {

  const [currentLocale, setCurrentLocale] = useState(defaultLocale);

  return (
    // To solve this, and enable dynamic locale modification, add a key property to the <IntlProvider>

    <IntlProvider
      messages={messages[currentLocale.split(/[-_]/)[0]]}
      locale={currentLocale}
      key={currentLocale}
      defaultLocale={defaultLocale} >
      <AuthProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Layout {...{currentLocale, setCurrentLocale}} />}>
              <Route path="login" element={<Login />} />
              <Route path="create" element={<Create />} />
              <Route path="reset" element={<Reset />} />
              <Route path="verify" element={<Verify />} />
              <Route path="settings">
                <Route index element={
                  <RequireAuth> <Settings /> </RequireAuth>
                } />
                <Route path="changePass" element={
                  <RequireAuth> <ChangePass /> </RequireAuth>
                } />
                <Route path="deleteAccount" element={
                  <RequireAuth> <DeleteAccount /> </RequireAuth>
                } />
                <Route path="createQueue" element={
                  <RequireAuth> <CreateQueue /> </RequireAuth>
                } />
                <Route path="transactions" element={
                    <RequireAuth>  <Transactions /> </RequireAuth>
                  } />
                <Route path=":businessId">
                  <Route index element={
                    <RequireAuth>  <QueueInfo />  </RequireAuth>
                  } />
                  <Route path="QRCode" element={
                    <RequireAuth>  <QRCode />  </RequireAuth>
                  } />
                  <Route path="queueDetails" element={
                    <RequireAuth>  <QueueDetails /> </RequireAuth>
                  } />
                </Route>
              </Route>

              <Route path="q">
                <Route index element={
                  <RequireAuth> <MainPage /> </RequireAuth>
                } />
                <Route
                path=":businessId"
                element={
                  <RequireAuth>  <MainPage />  </RequireAuth>
              } />
              </Route>

              <Route index element={
                  <RequireAuth> <HomePage /> </RequireAuth>
              } />
              
            </Route>
          </Routes>
        </BrowserRouter>
      </AuthProvider>

    </IntlProvider>

  );
}



function RequireAuth({ children }) {

  //const { currentUser, loading } = useOutletContext();
  const { currentUser } = useAuth();
  let location = useLocation();

  if (!currentUser) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (!currentUser.emailVerified) {
    return <Navigate to="/verify" state={{ from: location }} replace />;
  }

  return children;
}

